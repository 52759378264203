import React, { useState } from "react";
import InputField from "../InputField/InputField";
import Button from "../Button/Button";
import styled from "styled-components";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import Logo from "../../assets/Pay4Climate.png";
import PageTitle from "../PageTitle/PageTitle";
import { useFormik } from "formik";
import * as Yup from "yup";
import { codeVerfication } from "../../services/auth";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const SignInWrapper = styled.div`
  background-color: #091512;

  .full-page {
    max-width: 400px;
    width: 100%;
    // background-color: red;
    margin: auto;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .wrapper {
    height: auto;
    border: 2px solid #6f6f6f;
    width: 100%;
    padding: 3rem;

    background: #060e0c;
    border-radius: 5px;
    overflow-y: auto;
    margin: 10px;
    padding: 1rem 3rem 3rem 3rem;
  }
  .email-wrapper {
    .paragraph {
      font-size: 16px;
      line-height: 1.5;
      color: #fff;
    }
  }
`;
const TabsWrapper = styled.div`
  // display: flex;
  // background-color: green;
  // display: grid;
  // grid-template-columns: repeat(2, 1fr);
  display: flex;
  justify-content: center;
  width: 100%;
  text-align: center;
  margin-bottom: 2.4rem;

  h1 {
    // background: linear-gradient(
    //   261.78deg,
    //   rgb(190, 238, 98) -1.89%,
    //   rgb(104, 206, 199) 99.57%
    // );

    padding: 5px;
    border-radius: 5px;
    width: 100%;
    font-size: 2.4rem;
    color: #fff;
  }
`;

const VerificationSchema = Yup.object({
  token: Yup.string()
    .matches(/^\d{3,4}$/, "Verification code must be a 3 or 4-digit number")
    .required("Verification code is required"),
});

const Verification = () => {
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handleCode = (value) => {
    setIsLoading(true);
    console.log(value);
    codeVerfication(value)
      .then((res) => {
        console.log(res);
        if (res?.status == 200) {
          console.log(res?.data?.message);
          toast.success(res?.data?.message);
          navigate("/reset-password");
        } else {
          // console.log(res?.response);
          toast.error(res?.response?.data?.errors?.token[0]);
          // toast.error("ojk");
        }
      })
      .catch((err) => {
        console.log(err);
        // toast.error(res?.response?.data?.errors?.email[0]);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const formik = useFormik({
    initialValues: { token: "" },
    validationSchema: VerificationSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      console.log(values, "values");
      handleCode(values);
    },
  });
  return (
    <>
      <PageTitle title="Pay4Climate - verify code" />
      <form onSubmit={formik.handleSubmit}>
        <SignInWrapper>
          <div className="full-page">
            <div className="wrapper">
              <div className="" style={{ textAlign: "center" }}>
                <img src={Logo} style={{ maxWidth: "380px", width: "100%" }} />
              </div>
              <TabsWrapper>
                <h1>Verify Code</h1>
              </TabsWrapper>
              <div className="email-wrapper">
                <p className="paragraph">Verify Code</p>
                <InputField
                  placeholder={"Verification Code"}
                  style={{ marginBottom: "1.4rem" }}
                  name="token"
                  type="number"
                  onChange={formik.handleChange}
                  value={formik.values.token}
                  error={formik.touched.token && Boolean(formik.errors.token)}
                  helperText={formik.touched.token && formik.errors.token}
                  onBlur={formik.handleBlur}
                />
                {/* <Link to="/reset-password"> */}
                <Button type="submit" loading={isLoading}>
                  Continue
                </Button>
                {/* </Link> */}
              </div>
            </div>
          </div>
        </SignInWrapper>
      </form>
    </>
  );
};

export default Verification;
