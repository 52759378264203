import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Button from "../Button/Button";
import InputField from "../InputField/InputField";
import PageTitle from "../PageTitle/PageTitle";
import { useFormik } from "formik";
import * as Yup from "yup";
import { signup } from "../../services/auth";
import { toast } from "react-toastify";
import CountryCode from "../CountryCode/CountryCode";

const SignUpWrapper = styled.div`
  // background-color: green;
  .paragraph {
    font-size: 16px;
    line-height: 1.5;
    color: #fff;
  }
  .signup-select {
    height: 41px;
    padding: 10px 20px;
    color: #fff;
    border: none;
    border-radius: 5px;
    width: 100%;
    background: #060e0c;
    border: 2px solid #6f6f6f;
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 1.4rem;
    /** for the dropdown indicator */
    appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 1em;
  }
`;

const UserSignupSchema = Yup.object({
  name: Yup.string()
    .matches(/^[a-zA-Z\s]*$/, "Only letters and spaces are allowed")
    // .min(6, "Name must be at least 6 characters")
    .required("Name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .matches(
      /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
      "Invalid email format"
    )
    .required("Required"),
  contact: Yup.string()
    .matches(/^[0-9]{10}$/, "Contact number must be 10 digits")
    .required("Required"),
  country: Yup.string().required("Required"),
  address: Yup.string().required("Required"),
  password: Yup.string()
    .required("Required")
    .min(8, "Password must be at least 8 characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/,
      "Password must contain at least 1 lowercase letter, 1 uppercase letter, 1 digit, and 1 special character"
    ),

  newpassword: Yup.string()
    .required("Required")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

const Signup = ({ setIsLogin }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [countries, setCountries] = useState();

  const [selectContactNo, setSelectContactNo] = useState();
  const [phoneCode, setPhoneCode] = useState("+92");

  const sendUserDetail = (values) => {
    setIsLoading(true);
    const { newpassword, country, contact, ...valuesWithoutNewPassword } =
      values;

    const modifiedValues = {
      ...valuesWithoutNewPassword,
      country_id: values.country,
      phone_number: values.contact,
      password_confirmation: values.newpassword,
    };
    console.log(modifiedValues, "fetchUserDetail");
    signup(modifiedValues)
      .then((res) => {
        if (res?.status == 200) {
          console.log(res?.data?.message);
          toast.success(res?.data?.message);
          setIsLogin(true);
        } else {
          console.log(res?.response?.data?.errors?.email[0]);
          toast.error(res?.response?.data?.errors?.email[0]);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setIsLoading(false);
      });
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      contact: "",
      country: "",
      address: "",
      password: "",
      newpassword: "",
    },
    validationSchema: UserSignupSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      // console.log("Submitted email:", values);
      sendUserDetail(values);
    },
  });

  const fetchAllCountriesDetails = async () => {
    await fetch(
      "https://dev74.instantsolutionslab.site/api/get-all-home-countries",
      {
        method: "GET",
        headers: new Headers({
          // Authorization: "Bearer " + token,
          Accept: "application/json",
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setCountries(data.data); //get data in data
      });
  };

  useEffect(() => {
    fetchAllCountriesDetails();
  }, []);

  useEffect(() => {
    setCountryCode();
  }, [formik.values.country]);

  const setCountryCode = () => {
    if (formik.values.country) {
      const country = countries?.find(
        (country) => country?.id == formik.values.country
      );
      setPhoneCode(country?.phonecode);
      console.log(country, "code count");
    }
  };

  console.log(formik.values.country, "countries");
  console.log(selectContactNo, "selectContactNo");

  return (
    <>
      <PageTitle title="Pay4Climate - signup" />
      <form onSubmit={formik.handleSubmit}>
        <SignUpWrapper>
          <p className="paragraph">Name</p>
          <InputField
            placeholder={"Enter an email"}
            style={{ marginBottom: "1.4rem" }}
            name="name"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.name}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            onBlur={formik.handleBlur}
          />
          <p className="paragraph">Email</p>
          <InputField
            placeholder={"Enter an email"}
            style={{ marginBottom: "1.4rem" }}
            name="email"
            type="email"
            onChange={formik.handleChange}
            value={formik.values.email}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            onBlur={formik.handleBlur}
          />

          <p className="paragraph">Country</p>
          {/* <InputField
            placeholder={"Enter country"}
            style={{ marginBottom: "1.4rem" }}
            name="country"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.country}
            error={formik.touched.country && Boolean(formik.errors.country)}
            helperText={formik.touched.country && formik.errors.country}
            onBlur={formik.handleBlur}
          /> */}

          <div style={{ position: "relative" }}>
            <select
              // name="countryId"
              name="country"
              className="form-control signup-select"
              // value={countryId}
              value={formik.values.country}
              onChange={formik.handleChange}
              // error={formik.touched.country && Boolean(formik.errors.country)}
              // helperText={formik.touched.country && formik.errors.country}
              // onChange={(e) => {
              //   // fetchAllStatesDetailsByCountry(e.target.value);
              //   // formik.setFieldValue("countryId", e.target.value);
              // }}

              onBlur={formik.handleBlur}
            >
              <option
                value=""
                disabled
                selected
                style={{ color: "rgb(118, 118, 118)" }}
              >
                Select Country
              </option>
              {countries?.map((country) => {
                return (
                  <>
                    console.log(country," ")
                    <option
                      key={country?.id}
                      value={country?.id}
                      // selected={(e) => {
                      //   console.log(e);
                      //   setSelectContactNo(country);
                      // }}
                    >
                      {country?.name}
                    </option>
                  </>
                );
              })}
            </select>
            {formik.touched.country && Boolean(formik?.errors?.country) ? (
              <div
                style={{
                  position: "absolute",
                  bottom: "-18px",
                  left: "1px",
                  fontSize: "12px",
                  color: "red",
                }}
              >
                {formik.touched.country && formik?.errors.country}
              </div>
            ) : null}
          </div>

          <p className="paragraph">Contact No</p>
          {/* <InputField
            placeholder={"Enter contact no."}
            style={{ marginBottom: "1.4rem" }}
            name="contact"
            type="number"
            onChange={formik.handleChange}
            value={formik.values.contact}
            error={formik.touched.contact && Boolean(formik.errors.contact)}
            helperText={formik.touched.contact && formik.errors.contact}
            onBlur={formik.handleBlur}
          /> */}
          <CountryCode
            placeholder={"Enter contact no."}
            name="contact"
            type="number"
            onChange={formik.handleChange}
            value={formik.values.contact}
            error={formik.touched.contact && Boolean(formik.errors.contact)}
            helperText={formik.touched.contact && formik.errors.contact}
            onBlur={formik.handleBlur}
            phoneCode={phoneCode}
          />

          <p className="paragraph">Address</p>
          <InputField
            placeholder={"Enter an Address"}
            style={{ marginBottom: "1.4rem" }}
            name="address"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.address}
            error={formik.touched.address && Boolean(formik.errors.address)}
            helperText={formik.touched.address && formik.errors.address}
            onBlur={formik.handleBlur}
          />
          <p className="paragraph">Password</p>
          <InputField
            placeholder={"Enter password"}
            type="password"
            name="password"
            onChange={formik.handleChange}
            value={formik.values.password}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            onBlur={formik.handleBlur}
          />
          <p className="paragraph" style={{ marginTop: "1.4rem" }}>
            Confirm Password
          </p>
          <InputField
            placeholder={"Enter confirm password"}
            type="password"
            name="newpassword"
            onChange={formik.handleChange}
            value={formik.values.newpassword}
            error={
              formik.touched.newpassword && Boolean(formik.errors.newpassword)
            }
            helperText={formik.touched.newpassword && formik.errors.newpassword}
            onBlur={formik.handleBlur}
          />
          <Button
            type="submit"
            loading={isLoading}
            si
            style={{ marginTop: "1.8rem" }}
          >
            Continue
          </Button>
        </SignUpWrapper>
      </form>
    </>
  );
};

export default Signup;
