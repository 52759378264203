import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Admin from "./component/Admin/Admin";
import NotFound from "./component/NotFund/NotFound";
import { ToastContainer } from "react-toastify";

const AdminRoutes = () => {
  return (
    <div>
      <Routes>
        <Route exact path="/" element={<Navigate to="/admin" />} />
        <Route exact path="/admin" element={<Admin />} />
        {/* <Route path="*" element={<NotFound />} /> */}
        <Route path="*" element={<Navigate to="/admin" />} />
      </Routes>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
};

export default AdminRoutes;
