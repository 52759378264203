import React from "react";
import Blog from "../../assets/blog/blog1.jpg";
import styled from "styled-components";

const NewsFeedWrapper = styled.div`
  margin-bottom: 1.6rem;
  .wrapper {
    display: flex;
    gap: 1.2rem;
  }
  .image-wrapper {
    width: 86px;
    height: 100px;
    img {
      width: 86px;
      height: 100px;
      object-fit: cover;
    }
  }
  .heading-wrapper {
    .main-heading {
      font-size: 1.6rem;
      font-weight: 700;
    }
    .sub-heading {
      font-size: 1.4rem;
    }
  }
`;

const Newsfeed = ({ text, body }) => {
  return (
    <NewsFeedWrapper>
      <div className="wrapper">
        <div className="image-wrapper">
          <img src={Blog} />
        </div>
        <div className="heading-wrapper">
          {/* <p className="main-heading">Lorem ipsum</p> */}
          {/* <p className="sub-heading">
            It is a long established fact that a reader will be distracted by
            the readable content of a page when looking at its layout. The point
            .
          </p> */}
          {/* <p className="main-heading">{text}</p> */}
          <p className="main-heading">{body}</p>
        </div>
      </div>
    </NewsFeedWrapper>
  );
};

export default Newsfeed;
