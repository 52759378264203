import React, { useState } from "react";
import styled from "styled-components";
import DashboardStats from "../../component/DashboardStats/DashboardStats";
import DoughnutChart from "../../component/PieChart/PieChart";
import Newsfeed from "../../component/Newsfeed/Newsfeed";
import TableComponent from "../../component/TableComponent/TableComponent";
import PageTitle from "../../component/PageTitle/PageTitle";
import { transactionsHistory } from "../../services/user";

import { BsArrowUpRight } from "react-icons/bs";
import { useEffect } from "react";

const IconWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
`;

const Icons = () => {
  return (
    <IconWrapper>
      <a
        href="https://tronscan.org/#/transaction/9944fbe699b7d3cdbfdf996d85a686920b0f99944f179942db1ec8b13a012202"
        target="_blank"
      >
        TRONSCAN <BsArrowUpRight />
      </a>
    </IconWrapper>
  );
};

const columns = [
  { Header: "Date ", accessor: "column1" },
  { Header: "Amount", accessor: "column2" },
  { Header: "Removed ton", accessor: "column3" },
  { Header: "Blockchain", accessor: "column4" },
  { Header: "Type", accessor: "column5" },
  { Header: "Source (CDR)", accessor: "column6" },
  { Header: "Explorer", accessor: "column7" },
  // Add more columns as needed
];

const data = [
  {
    column1: "27/11/2022 ",
    column2: "0.5ETH ($600)",
    column3: "1.5 ton",
    column4: "Ethereum",
    column5: "ERC 721 ",
    column6: " Will Solutions",
    column7: <Icons />,
  },
  // {
  //   column1: "27/11/2022 ",
  //   column2: "0.5ETH ($600)",
  //   column3: "1.5 ton",
  //   column4: "Ethereum",
  //   column5: "ERC 721 ",
  //   column6: " Will Solutions",
  //   column7: <Icons />,
  // },
  // {
  //   column1: "27/11/2022 ",
  //   column2: "0.5ETH ($600)",
  //   column3: "1.5 ton",
  //   column4: "Ethereum",
  //   column5: "ERC 721 ",
  //   column6: " Will Solutions",
  //   column7: <Icons />,
  // },
  // {
  //   column1: "27/11/2022 ",
  //   column2: "0.5ETH ($600)",
  //   column3: "1.5 ton",
  //   column4: "Ethereum",
  //   column5: "ERC 721 ",
  //   column6: " Will Solutions",
  //   column7: <Icons />,
  // },
  // {
  //   column1: "27/11/2022 ",
  //   column2: "0.5ETH ($600)",
  //   column3: "1.5 ton",
  //   column4: "Ethereum",
  //   column5: "ERC 721 ",
  //   column6: " Will Solutions",
  //   column7: <Icons />,
  // },
  // {
  //   column1: "27/11/2022 ",
  //   column2: "0.5ETH ($600)",
  //   column3: "1.5 ton",
  //   column4: "Ethereum",
  //   column5: "ERC 721 ",
  //   column6: " Will Solutions",
  //   column7: <Icons />,
  // },
  // {
  //   column1: "27/11/2022 ",
  //   column2: "0.5ETH ($600)",
  //   column3: "1.5 ton",
  //   column4: "Ethereum",
  //   column5: "ERC 721 ",
  //   column6: " Will Solutions",
  //   column7: <Icons />,
  // },
  // {
  //   column1: "27/11/2022 ",
  //   column2: "0.5ETH ($600)",
  //   column3: "1.5 ton",
  //   column4: "Ethereum",
  //   column5: "ERC 721 ",
  //   column6: " Will Solutions",
  //   column7: <Icons />,
  // },
  // {
  //   column1: "27/11/2022 ",
  //   column2: "0.5ETH ($600)",
  //   column3: "1.5 ton",
  //   column4: "Ethereum",
  //   column5: "ERC 721 ",
  //   column6: " Will Solutions",
  //   column7: <Icons />,
  // },
  // {
  //   column1: "27/11/2022 ",
  //   column2: "0.5ETH ($600)",
  //   column3: "1.5 ton",
  //   column4: "Ethereum",
  //   column5: "ERC 721 ",
  //   column6: " Will Solutions",
  //   column7: <Icons />,
  // },
  // {
  //   column1: "27/11/2022 ",
  //   column2: "0.5ETH ($600)",
  //   column3: "1.5 ton",
  //   column4: "Ethereum",
  //   column5: "ERC 721 ",
  //   column6: " Will Solutions",
  //   column7: <Icons />,
  // },
  // {
  //   column1: "27/11/2022 ",
  //   column2: "0.5ETH ($600)",
  //   column3: "1.5 ton",
  //   column4: "Ethereum",
  //   column5: "ERC 721 ",
  //   column6: " Will Solutions",
  //   column7: <Icons />,
  // },
  // {
  //   column1: "27/11/2022 ",
  //   column2: "0.5ETH ($600)",
  //   column3: "1.5 ton",
  //   column4: "Ethereum",
  //   column5: "ERC 721 ",
  //   column6: " Will Solutions",
  //   column7: <Icons />,
  // },
  // {
  //   column1: "27/11/2022 ",
  //   column2: "0.5ETH ($600)",
  //   column3: "1.5 ton",
  //   column4: "Ethereum",
  //   column5: "ERC 721 ",
  //   column6: " Will Solutions",
  //   column7: <Icons />,
  // },

  // Add more data objects as needed
];

const pieChart1Data = [
  {
    id: 0,
    value: 30,
    label: "Afforestation and Reforestation",
    color: "#36a2eb",
  },
  { id: 1, value: 10, label: "Soil Carbon Sequestration", color: "#ff6384" },
  {
    id: 2,
    value: 12,
    label: "Carbon Capture and Storage (CCS)",
    color: "#4bc0c0",
  },
  {
    id: 3,
    value: 15,
    label: "Bioenergy with Carbon Capture(BECCS)",
    color: "#ff9f40",
  },
  {
    id: 4,
    value: 11,
    label: "Ocean-based Carbon Capture(OBCC)",
    color: "#9966ff",
  },
  {
    id: 5,
    value: 13,
    label: "Direct Air Capture (DAC)",
    color: "#ffcd56",
  },
  {
    id: 6,
    value: 14,
    label: "Enhanced Weathering",
    color: "#c9cbcf",
  },
];

const pieChart2Data = [
  { id: 0, value: 100, label: "Removed from atmosphere", color: "#36a2eb" },
  { id: 1, value: 23, label: "To be retired", color: "#ff6384" },
  // { id: 2, value: 123, label: "Total contributed", color: "#4bc0c0" },
];

const DashboardWrapper = styled.div`
  color: #fff;
  h1 {
    font-size: 4rem;
    color: inherit;
  }
  h6 {
    color: #fff;
    font-size: 2rem;
    margin-bottom: 2.4rem;
  }
  h5 {
    color: #fff;
    font-size: 2rem;
    margin: 1.2rem 0;
  }
`;
const StatsWrapper = styled.div`
  // display: flex;
  display: grid;
  grid-template-columns: 0.8fr 1.1fr 1fr;
  border-bottom: 2px solid #6f6f6f;
`;

const Dashboard = () => {
  const [isdata, setIsData] = useState([]);
  console.log();

  console.log(isdata, "isdata");

  // transactionsHistory();

  useEffect(() => {
    // setTableData(() => setTableData(transactionsHistory));
    fetch("https://dev4.instantsolutionslab.site/api/get-my-transactions", {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((resp) => resp.json())
      .then((json) => setIsData(json?.data?.data));
  }, []);

  console.log(isdata, "tableData");

  function convertISOToCustomFormat(isoDate) {
    const date = new Date(isoDate);
    const day = date.getUTCDate().toString().padStart(2, "0");
    const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
    const year = date.getUTCFullYear();
    return `${day}/${month}/${year}`;
  }

  const data = isdata.map((tableItem) => ({
    column1: convertISOToCustomFormat(tableItem.created_at),
    column2: `${tableItem.amount?.slice(0, 4)}USDT`,
    // column3: "1.5 ton",
    // column3: tableItem?.removed_tons + " ton",
    // column3: tableItem?.removed_tons + " ton",
    column3:
      tableItem?.removed_tons +
      " ton" +
      "  (" +
      tableItem?.removed_tons * 1000 +
      "Kg)",
    column4: "Tron",
    column5: "TRC 20",
    column6: "Toucan",
    column7: <Icons />,
  }));

  // const data = [
  //   isdata.map(tableItem => tableItem){
  //     <>
  //     {
  //       column1: "27/11/2022 ",
  //       column2: "0.5ETH ($600)",
  //       column3: "1.5 ton",
  //       column4: "Ethereum",
  //       column5: "ERC 721 ",
  //       column6: " Will Solutions",
  //       column7: <Icons />,
  //     },
  //     </>

  //   }
  // ]

  return (
    <>
      <PageTitle title="Pay4Climate - dashboard" />
      <DashboardWrapper>
        <h1>Dashboard</h1>
        <StatsWrapper>
          <div>
            <DashboardStats
              heading={"Removed from atmosphere"}
              count={"100"}
              tons={"Tons"}
              co2={"co2"}
            />
            <DashboardStats
              heading={"To be retired"}
              count={"23"}
              tons={"Tons"}
              co2={"co2"}
            />
            <DashboardStats
              heading={"Total contributed"}
              count={"123"}
              tons={"Tons"}
              co2={"co2"}
            />
          </div>
          <div>
            <h6>Disbribution Charts</h6>
            <DoughnutChart data={pieChart1Data} />
            <DoughnutChart data={pieChart2Data} />
          </div>
          <div>
            <h6>News feed</h6>
            <Newsfeed
              text="n°1"
              body="This year has truly been significant for carbon dioxide removals, or CDR, highlighted by a staggering 437% increase in CDR purchases. This increase is notable for the first half of 2023 compared to the entire 2022."
            />
            <Newsfeed
              text="n°2"
              body="In 2022, UBS formed long-term collaborations with two Swiss companies to advance CDR technologies. Climeworks specialises in direct air capture (#DAC) and storage of CO2 and Neustark specialises in the petrification of atmospheric CO2 in recycled concrete."
            />

            {/* <DoughnutChart /> */}
          </div>
        </StatsWrapper>
        <h5>History</h5>

        <TableComponent
          columns={columns}
          data={data}
          isDisplayPagnation={false}
        />
      </DashboardWrapper>
    </>
  );
};

export default Dashboard;
