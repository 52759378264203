import React from "react";
import styled from "styled-components";

const SidebarList = styled.li`
  // background: blue;
  font-size: 2rem;
  display: flex;
  align-items: center;
  line-height: 1.5;
  color: #fff;
  cursor: pointer;
  margin-bottom: 0.8rem;
  background: ${(props) => !props.$activeStyle || "#BF4F74"};
  .sidebar-icon {
    display: block;

    & svg {
      font-size: 2rem;
      vertical-align: middle;
    }
  }
  .sidebar-text {
    display: block;
    margin-left: 0.8rem;
  }
`;
const SidebarLi = ({ text, icon, handleActiveTab, activeStyle, ...props }) => {
  console.log(activeStyle, handleActiveTab);
  return (
    <SidebarList
      onClick={handleActiveTab}
      $activeStyle={activeStyle}
      {...props}
    >
      <span className="sidebar-icon">{icon}</span>
      <span className="sidebar-text">{text}</span>
    </SidebarList>
  );
};

export default SidebarLi;
