// import React from 'react'

// const Provider = () => {
//   return (
//     <div>Provider</div>
//   )
// }

// export default Provider

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Button from "../../Button/Button";
// import Modal from "../../Modal/Modal";
import BootstrapModal from "../../Modal/Modal";
import {
  AiOutlinePlus,
  AiTwotoneEdit,
  AiTwotoneSetting,
  AiFillCloseCircle,
} from "react-icons/ai";
import { BiSolidTime } from "react-icons/bi";
import TableComponent from "../../TableComponent/TableComponent";
import { LuEdit } from "react-icons/lu";
// import FaEdit from "react-icons/fa";
// import CiEdit from "react-icons/ci";

const CustomerWrapper = styled.div`
  // background: blue;/
  font-size: 2rem;
  line-height: 1.5;
  color: #fff;

  display: flex;
  justify-content: space-between;
  align-items: center;
  h1 {
    color: #fff;
  }
`;

const columns = [
  { Header: "Name ", accessor: "column1" },
  { Header: "Chain", accessor: "column2" },
  { Header: "Address", accessor: "column3" },
  { Header: "Total tons", accessor: "column4" },
  { Header: "Created at", accessor: "column5" },
  { Header: "Actions", accessor: "column6" },
  // Add more columns as needed
];
const IconWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
`;
const Icons = () => {
  return (
    <IconWrapper>
      {/* <AiTwotoneEdit /> |
      <AiTwotoneSetting /> |
      <AiFillCloseCircle /> |
      <BiSolidTime /> */}
      {/* <CiEdit /> */}
      {/* <div onClick={() => console.log("handle click")}> */}
      <LuEdit />
      {/* </div> */}
    </IconWrapper>
  );
};

const data = [
  {
    column1: "Toucan ",
    column2: "Tron",
    column3: "0*020991...",
    column4: "21.5 ton",
    column5: "12/10/2022",
    column6: <Icons />,
  },
  {
    column1: "Aider",
    column2: "Tron",
    column3: "0*020991...",
    column4: "21.5 ton",
    column5: "12/10/2022",
    column6: <Icons />,
  },
  {
    column1: "Will Climate ",
    column2: "Tron",
    column3: "0*020991...",
    column4: "21.5 ton",
    column5: "12/10/2022",
    column6: <Icons />,
  },
  {
    column1: "Green planet ",
    column2: "Tron",
    column3: "0*020991...",
    column4: "21.5 ton",
    column5: "12/10/2022",
    column6: <Icons />,
  },

  // Add more data objects as needed
];

const Provider = () => {
  // const [modalOpen, setModalOpen] = useState(false);

  const [modalIsOpen, setIsOpen] = useState(false);
  const [transactionHistory, setTransactionHiistory] = useState(false);

  // const handleOpenModal = () => {
  //   setModalOpen(true);
  // };

  // const handleCloseModal = () => {
  //   setModalOpen(false);
  // };

  // const fetchTransactionHistory = () => {
  //   var myHeaders = new Headers();
  //   myHeaders.append("Accept", "application/json");
  //   myHeaders.append(
  //     "Authorization",
  //     `Bearer ${localStorage.getItem("token")}`
  //   );

  //   var requestOptions = {
  //     method: "GET",
  //     headers: myHeaders,
  //     // Authorization: `Bearer ${localStorage.getItem("token")}`,
  //     redirect: "follow",
  //   };

  //   fetch(
  //     // "https://dev4.instantsolutionslab.site/api/get-all-transaction-history",
  //     process.env.REACT_APP_URL + "/get-all-transaction-history",
  //     requestOptions
  //   )
  //     .then((response) => response.json())
  //     .then((result) => {
  //       // console.log(result, "result");
  //       setTransactionHiistory(result);
  //     })
  //     .catch((error) => console.log("error", error));
  // };

  // console.log(transactionHistory, "transactionHistory");
  // useEffect(() => {
  //   fetchTransactionHistory();
  // }, []);

  return (
    <>
      <BootstrapModal modalIsOpen={modalIsOpen} setIsOpen={setIsOpen}>
        <h2>Modal Content</h2>
        <p>This is the content of the modal.</p>
      </BootstrapModal>
      {/* <Modal /> */}
      <BootstrapModal />
      <CustomerWrapper>
        <h1>CDR Providers</h1>
        <Button style={{ width: "fit-content" }}>
          Add CDR providers
          <AiOutlinePlus style={{ verticalAlign: "middle" }} />
        </Button>
      </CustomerWrapper>
      <TableComponent columns={columns} data={data} handleLastItemClick2 />
    </>
  );
};

export default Provider;
