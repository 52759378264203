import React, { useState } from "react";
import styled from "styled-components";
import Sidebar from "../Sidebar/Sidebar";
import Detail from "./Detail/Detail";
import Modal from "../Modal/Modal";
import Provider from "./Providers/Provider";
import AdminHostory from "../AdminHistory/AdminHistory";
import AdminSettings from "../AdminSettings/AdminSettings";

const AdminDashboardWrapper = styled.div`
  display: grid;
  grid-template-columns: 20fr 80fr;
  height: 100vh;
`;
const SidebarWrapper = styled.div`
  // background: red;
  border-right: 2px solid #6f6f6f;
`;
const DetailsWrapper = styled.div`
  // background: yellow;
  padding: 1.6rem 3.2rem;
`;

const sidebarItems = [
  { label: "Dashboard" },
  { label: "Reports" },
  { label: "Settings" },
  { isSectionTitle: true, label: "Secondary" },
  { label: "Profile" },
  { label: "Messages" },
  { label: "Notifications" },
  // Add more items as needed
];

const Admin = () => {
  const [activeTab, setActiveTab] = useState(0);
  return (
    <>
      <AdminDashboardWrapper>
        <SidebarWrapper>
          <Sidebar
            item={sidebarItems}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        </SidebarWrapper>
        <DetailsWrapper>
          {/* {activeTab === 0 && <Dashboard />} */}
          {activeTab === 0 && <Detail />}
          {/* {activeTab === 1 && <Settings />} */}
          {activeTab === 1 && <Provider />}
          {activeTab === 2 && <AdminHostory />}
          {activeTab === 3 && <AdminSettings />}
        </DetailsWrapper>
      </AdminDashboardWrapper>
    </>
  );
};

export default Admin;
