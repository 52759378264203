// import React, { useState, useEffect } from "react";
// import axios from "axios";

// const TodoListWithAPI = () => {
//   const [todos, setTodos] = useState([]);
//   const [newTodo, setNewTodo] = useState("");

//   useEffect(() => {
//     fetchData();
//   }, []);

//   const fetchData = async () => {
//     try {
//       const response = await axios.get(
//         process.env.REACT_APP_URL + "/trading-pair",
//         {
//           headers: {
//             Authorization: `Bearer ${localStorage?.getItem("token")}`,
//           },
//         }
//       );
//       setTodos(response.data);
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     }
//   };

//   return (
//     <div>
//       <h1>Todo List</h1>
//       <ul>
//         {todos?.name?.map((todo) => (
//           <TodoItem key={todo.id} todo={todo} onEdit={handleEditTodo} />
//         ))}
//       </ul>
//       <input
//         type="text"
//         value={newTodo}
//         onChange={(e) => setNewTodo(e.target.value)}
//       />
//       <button onClick={handleAddTodo}>Add Todo</button>
//     </div>
//   );
// };

// const TodoItem = ({ todo, onEdit }) => {
//   const [isEditing, setIsEditing] = useState(false);
//   const [editedText, setEditedText] = useState(todo.text);

//   const handleEditClick = () => {
//     setIsEditing(true);
//   };

//   const handleSaveClick = () => {
//     setIsEditing(false);
//     onEdit(todo.id, editedText);
//   };

//   const handleCancelClick = () => {
//     setIsEditing(false);
//     setEditedText(todo.text);
//   };

// const handleInputChange = (event) => {
//   setEditedText(event.target.value);
// };

//   return (
//     <li>
//       {isEditing ? (
//         <>
//           <input type="text" value={editedText} onChange={handleInputChange} />
//           <button onClick={handleSaveClick}>Save</button>
//           <button onClick={handleCancelClick}>Cancel</button>
//         </>
//       ) : (
//         <>
//           <span>{todo.text}</span>
//           <button onClick={handleEditClick}>Edit</button>
//         </>
//       )}
//     </li>
//   );
// };

// export default TodoListWithAPI;

import React, { useState } from "react";
import styled from "styled-components";
import InputField from "../InputField/InputField";
import Button from "../Button/Button";
import { List, ListItem, ListItemText, IconButton } from "@mui/material";
import { AiFillEdit } from "react-icons/ai";

const EditAbleWrapper = styled.div`
  color: #fff;
  font-size: 2.4rem;
  margin-left: 1rem;
`;

const UlList = styled.ul`
  margin-left: 1rem;
  list-style: number !important;
`;
const LiList = styled.li`
  // font-size: 1.4rem;
  // margin-left: 1rem;
  // list-style: number;

  font-size: 1.6rem;
  margin-left: 1rem;
  list-style: number;
  margin-bottom: 0.2rem;
`;
const AddWrapper = styled.div`
  font-size: 1.4rem;
  // margin-left: 1rem;
  margin-top: 1rem;
  display: flex;
  .inputAdd {
    // width: 150px;
    width: fit-content;
  }
  .buttonAdd {
    width: fit-content;
  }
`;

const EditableListItem = ({
  data,
  value1,
  setValue1,
  placeholder,
  handleAddTodo,
  isEditValue,
  setEditValue,
  updateAPI,
}) => {
  console.log(data, "editable");
  const [isEditing, setIsEditing] = useState(false);
  const [editingIndex, setEditingIndex] = useState(-1);

  const handleSaveClick = () => {
    setIsEditing(false);
    setEditingIndex(-1);
    updateAPI();

    // onEdit(todo.id, editedText);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
    setEditingIndex(-1);
    // setEditedText(todo.text);
  };

  const handlePencilClick = (index) => {
    setIsEditing(true);
    setEditingIndex(index);
    console.log(index);
  };
  // console.log(editingIndex, item?.index);

  const handleInputChange = (e) => {
    // setEditValue((prev) => {...prev, name:e.target.value});
    setEditValue((prev) => ({
      ...prev,
      name: e.target.value,
    }));
    console.log(e.target.value);
  };

  return (
    <EditAbleWrapper>
      <ul>
        {data?.map((item, index) => {
          return (
            <>
              {editingIndex === item?.id - 1 ? (
                <>
                  <div style={{ display: "flex" }}>
                    <InputField
                      type="text"
                      value={isEditValue?.name}
                      // value={item?.name}
                      onChange={(e) => handleInputChange(e)}
                    />
                    {/* <button onClick={handleSaveClick}>Save</button> */}
                    <Button
                      onClick={handleSaveClick}
                      style={{
                        width: "auto",
                        marginRight: "1rem",
                        marginLeft: "1rem",
                      }}
                    >
                      Save
                    </Button>
                    <Button
                      onClick={handleCancelClick}
                      style={{ width: "auto" }}
                    >
                      Cancel
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  {/* <span>{todo.text}</span>
                  <button onClick={handleEditClick}>Edit</button> */}
                  <LiList>
                    <div style={{ display: "flex" }}>
                      {item?.name}{" "}
                      <AiFillEdit
                        onClick={() => {
                          handlePencilClick(index);
                          setEditValue({
                            name: item?.name,
                            id: item?.id,
                          });
                        }}
                      />
                    </div>
                  </LiList>
                </>
              )}
              {/* <IconButton onClick={() => handleEditClick(index)}> */}
            </>
          );
        })}
      </ul>
      <AddWrapper>
        <InputField
          type="text"
          className="inputAdd"
          value={value1}
          onChange={(e) => setValue1(e.target.value)}
          placeholder={placeholder}
          style={{ marginRight: "1rem" }}
        />
        {/* <button onClick={handleAddTodo}>Add Todo</button> */}
        <Button
          className="buttonAdd"
          disabled={value1?.length === 0}
          onClick={handleAddTodo}
        >
          Add
        </Button>
      </AddWrapper>
    </EditAbleWrapper>
  );
};

export default EditableListItem;
