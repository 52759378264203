import React, { useState, useEffect } from "react";
import styled from "styled-components";
import TableComponent from "../../component/TableComponent/TableComponent";
import { BsArrowUpRight } from "react-icons/bs";
import PageTitle from "../../component/PageTitle/PageTitle";

const IconWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
`;

const Icons = () => {
  return (
    <IconWrapper>
      <a
        href="https://tronscan.org/#/transaction/9944fbe699b7d3cdbfdf996d85a686920b0f99944f179942db1ec8b13a012202"
        target="_blank"
      >
        TRONSCAN <BsArrowUpRight />
      </a>
    </IconWrapper>
  );
};

const columns = [
  { Header: "Date ", accessor: "column1" },
  { Header: "Amount", accessor: "column2" },
  { Header: "Removed ton", accessor: "column3" },
  { Header: "Blockchain", accessor: "column4" },
  { Header: "Type", accessor: "column5" },
  { Header: "Source (CDR)", accessor: "column6" },
  { Header: "Explorer", accessor: "column7" },
  // Add more columns as needed
];

// const data = [];

const HistoryWrapper = styled.div`
  h1 {
    color: #fff;
    font-size: 4rem;
  }
`;

const History = () => {
  const [isdata, setIsData] = useState([]);
  const [historydata, setHistoryData] = useState("");

  const fetchUserHistory = async (pageNumber = 1) => {
    await fetch(
      `https://dev4.instantsolutionslab.site/api/get-my-transactions?page=${activePage}`,
      {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
      .then((resp) => resp.json())
      .then((json) => {
        setIsData(json?.data?.data);
        console.log(json?.data);
        setHistoryData(json?.data);
      });
  };
  // const [pageNumber, setPageNumber] = useState(1);
  const [activePage, setActivePage] = useState(1);
  useEffect(() => {
    fetchUserHistory();
    // setTableData(() => setTableData(transactionsHistory));
  }, []);

  console.log(historydata, "tableData");

  function convertISOToCustomFormat(isoDate) {
    const date = new Date(isoDate);
    const day = date.getUTCDate().toString().padStart(2, "0");
    const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
    const year = date.getUTCFullYear();
    return `${day}/${month}/${year}`;
  }
  console.log(isdata, "isdataisdata");
  const data = isdata.map((tableItem) => ({
    column1: convertISOToCustomFormat(tableItem.created_at),
    column2: `${tableItem.amount?.slice(0, 4)}USDT`,
    column3:
      tableItem?.removed_tons +
      " ton" +
      "  (" +
      tableItem?.removed_tons * 1000 +
      "Kg)",
    // column3: "1.5 ton",
    column4: "Tron",
    column5: "TRC 20",
    column6: "Toucan",
    column7: <Icons />,
  }));

  // const handlePageChange = (pageNumber) => {
  //   console.log(`active page is ${pageNumber}`);
  //   // setActivePage(pageNumber);
  //   fetchUserHistory(pageNumber);
  // };

  console.log(data, "data");
  return (
    <>
      <PageTitle title="Pay4Climate - history" />
      <HistoryWrapper>
        <h1>History</h1>
        <TableComponent
          columns={columns}
          data={data}
          activePage={historydata?.current_page}
          totalItemsCount={historydata?.total ?? 0}
          itemsCountPerPage={parseInt(historydata?.per_page)}
          onChange={(pageNumber) => fetchUserHistory(pageNumber)}
        />
      </HistoryWrapper>
    </>
  );
};

export default History;
