import React, { useState } from "react";
import styled from "styled-components";
import Button from "../Button/Button";
import InputField from "../InputField/InputField";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import PageTitle from "../PageTitle/PageTitle";
import { signin } from "../../services/auth";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const InputWrapper = styled.div`
  // background-color: green;
  .paragraph {
    font-size: 16px;
    line-height: 1.5;
    color: #fff;
  }
`;

const UserSigninSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .matches(
      /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
      "Invalid email format"
    )
    .required("Required"),
  password: Yup.string()
    .required("Required")
    .min(6, "Password must be at least 6 characters"),
  // .matches(
  //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/,
  //   "Password must contain at least 1 lowercase letter, 1 uppercase letter, 1 digit, and 1 special character"
  // ),
});

const Login = () => {
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const fetchUserDetail = (values) => {
    setIsLoading(true);
    signin(values)
      .then((res) => {
        if (Boolean(res?.data?.token)) {
          console.log(res?.data?.message);
          toast.success(res?.data?.message);
          setTimeout(() => {
            // navigate("/user/dashboard");
            window.location.href =
              "https://pay4climate.instantsolutionslab.site/user/dashboard";
            // window.location.reload();
          }, 1000);
        } else {
          console.log(res.data?.error[0]);
          toast.error(res.data?.error[0]);
        }
      })
      .catch((error) => {
        // Handle error
      })
      .finally(() => setIsLoading(false));
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: UserSigninSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      // console.log("Submitted email:", values);
      fetchUserDetail(values);
    },
  });

  return (
    <>
      <PageTitle title="Pay4Climate - login" />
      <form onSubmit={formik.handleSubmit}>
        <InputWrapper>
          <p className="paragraph">Username / email</p>
          <InputField
            placeholder={"Email"}
            style={{ marginBottom: "1.4rem" }}
            name="email"
            type="email"
            onChange={formik.handleChange}
            value={formik.values.email}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            onBlur={formik.handleBlur}
          />
          <p className="paragraph">Password</p>
          <InputField
            placeholder={"Password"}
            type="password"
            name="password"
            onChange={formik.handleChange}
            value={formik.values.password}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            onBlur={formik.handleBlur}
          />
          <Link to="/forgetpassword">
            <p
              className="paragraph"
              style={{ marginBottom: "1.4rem", textAlign: "end" }}
            >
              Forgot password?
            </p>
          </Link>
          {/* <Link to="/user/dashboard"> */}
          <Button type="submit" loading={isLoading}>
            Continue
          </Button>
          {/* </Link> */}
        </InputWrapper>
      </form>
    </>
  );
};

export default Login;
