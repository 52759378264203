import React, { useState } from "react";
import Select from "react-select";

const DynamicSelect = ({ options, onChange ,...props }) => {
  return (
    <Select
      options={options}
      onChange={onChange}
      classNamePrefix="filterreactselect"
      {...props}
    />
  );
};

// const ReactSelect = () => {
//   return (
//     <>
//       <DynamicSelect options={options} onChange={handleSelectChange} />
//     </>
//   );
// };

export default DynamicSelect;
