import React from "react";
import styled from "styled-components";
import PageTitle from "../../component/PageTitle/PageTitle";

const SupportWrapper = styled.div`
  h1 {
    color: #fff;
    font-size: 4rem;
  }
`;

const Support = () => {
  return (
    <>
      <PageTitle title="Pay4Climate - support" />
      <SupportWrapper>
        <h1>Support</h1>
      </SupportWrapper>
    </>
  );
};

export default Support;
