import React, { useState } from "react";
import SidebarLi from "../../../component/SidebarLi/SidebarLi";
import styled from "styled-components";
import { HiUsers } from "react-icons/hi";
import { GrProductHunt } from "react-icons/gr";
import { RiLogoutCircleRFill } from "react-icons/ri";
import { AiFillHome, AiFillSetting, AiOutlineHistory } from "react-icons/ai";
import { FaUserCircle } from "react-icons/fa";
import { MdContactSupport } from "react-icons/md";

const SidebarLogoutWrapper = styled.div`
  // background: green;

  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.6rem 3.2rem;
`;
const UnorderList = styled.ul`
  // background: blue;
`;

const SignOutWrapper = styled.p`
  // background: blue;/
  font-size: 2rem;
  line-height: 1.5;
  color: #fff;
`;

const UserSideBar = ({ activeTab, setActiveTab }) => {
  // const [activeTab, setActiveTab] = useState(0);
  console.log(activeTab, setActiveTab);
  const handleSignOut = () => {
    setTimeout(() => {
      localStorage.removeItem("token");
      window.location.href =
        "https://pay4climate.instantsolutionslab.site/signin";
    }, 500);
  };

  return (
    <SidebarLogoutWrapper className="sidebar">
      <UnorderList>
        <SidebarLi
          handleActiveTab={() => setActiveTab(0)}
          activeStyle={activeTab === 0}
          text="Dashboard"
          icon={<AiFillHome />}
        />
        <SidebarLi
          handleActiveTab={() => setActiveTab(1)}
          activeStyle={activeTab === 1}
          text="Settings"
          icon={<AiFillSetting />}
        />
        <SidebarLi
          handleActiveTab={() => setActiveTab(2)}
          activeStyle={activeTab === 2}
          text="History"
          icon={<AiOutlineHistory />}
        />
        <SidebarLi
          activeStyle={activeTab === 3}
          handleActiveTab={() => setActiveTab(3)}
          text="Profile"
          icon={<FaUserCircle />}
        />
        <SidebarLi
          handleActiveTab={() => setActiveTab(4)}
          activeStyle={activeTab === 4}
          text="Support"
          icon={<MdContactSupport />}
        />
      </UnorderList>
      <SignOutWrapper>
        <SidebarLi
          text="Signout"
          icon={<RiLogoutCircleRFill />}
          onClick={handleSignOut}
        />
      </SignOutWrapper>
    </SidebarLogoutWrapper>
  );
};

export default UserSideBar;
