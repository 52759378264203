import Grid from "@mui/material/Grid";
import { styled } from "@mui/system";
import PropTypes from "prop-types";
import Image1 from "../../assets/auth/image1.png";
// import Image from "next/image";
// import authImage from "@assets/coverImage.jpg";

const ImageContainer = styled("div")({
  width: "100%",
  height: "100vh",
  position: "relative",
  "& .auth-cover-image": {
    backgroundSize: "contain",
    width: "100%",
    height: "100%",
  },
});
// const FormContainer = styled("div")({
//   width: "100%",
//   height: "100%",
//   display: "flex",
//   flexDirection: "column",
// });

const AuthLayout = ({ children, src }) => (
  <Grid container sx={{ maxHeight: "100vh", overflow: "hidden" }}>
    <Grid item lg={6} md={6} xs={12} sm={12}>
      {/* <FormContainer> */}
      {children}
      {/* </FormContainer> */}
    </Grid>
    <Grid item lg={6} md={6}>
      <ImageContainer>
        <img src={Image1} className="auth-cover-image" />
        {/* <img
          src={src.png}
          alt="Picture of the author"
          objectFit="cover"
          layout="fill"
          priority
        /> */}
        {/* <Image
          src={src}
          alt="Picture of the author"
          objectFit="cover"
          layout="fill"
          priority
        /> */}
      </ImageContainer>
    </Grid>
  </Grid>
);

AuthLayout.propTypes = {
  children: PropTypes.node,
  src: PropTypes.object,
};

AuthLayout.defaultProps = {
  children: <div>Hello</div>,
  src: PropTypes.object,
};

export default AuthLayout;
