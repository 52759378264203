import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Button from "../../Button/Button";
import InputField from "../../InputField/InputField";
import Modal from "../../Modal/Modal";
import CountryCode from "../../CountryCode/CountryCode";
import {
  AiOutlinePlus,
  AiTwotoneEdit,
  AiTwotoneSetting,
  AiFillCloseCircle,
} from "react-icons/ai";
import { BiSolidTime } from "react-icons/bi";
import TableComponent from "../../TableComponent/TableComponent";
import BootstrapModal from "../../Modal/Modal";
import { useFormik } from "formik";
import * as Yup from "yup";
import { AiOutlineCloseCircle } from "react-icons/ai";
import axios from "axios";
import { BsFillQuestionCircleFill } from "react-icons/bs";
// import InputField from "../";
// import InputField from "./InputField/InputField";
// InputField

const SignUpWrapper = styled.div`
  // background-color: green;
  .paragraph {
    font-size: 16px;
    line-height: 1.5;
    color: #fff;
  }
  .signup-select {
    height: 41px;
    padding: 10px 20px;
    color: #fff;
    border: none;
    border-radius: 5px;
    width: 100%;
    background: #060e0c;
    border: 2px solid #6f6f6f;
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 1.4rem;

    /** for the dropdown indicator */
    appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 1em;
  }
`;

const CustomerWrapper = styled.div`
  // background: blue;/
  font-size: 2rem;
  line-height: 1.5;
  color: #fff;

  display: flex;
  justify-content: space-between;
  align-items: center;
  h1 {
    color: #fff;
  }
`;

const columns = [
  { Header: "Name ", accessor: "column1" },
  { Header: "Percentage", accessor: "column2" },
  { Header: "Total tons", accessor: "column3" },
  { Header: "Created at", accessor: "column4" },
  { Header: "Actions", accessor: "column5" },
  // Add more columns as needed
];
const IconWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
`;

const CheckboxWrapper = styled.div`
margin-left:1rem;
color:#fff;
h2{
  font-size:2.6rem;
  display: flex;
  align-items: center;
  gap:1rem;
  margin-bottom: 0.2rem;
  color:#fff;
  svg{
    width:1.8rem;
    height:1.8rem;
  }
}}

h3{
  font-size:2rem;
  display: flex;
  align-items: center;
  gap:1rem;
  margin-bottom: 0.2rem;
  color:#fff;
  svg{
    width:1.8rem;
    height:1.8rem;
  }
}}


`;

const PWrapper = styled.div`
  font-size: 2.6rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 0.2rem;
  color: #fff;
`;

const UserEditSchema = Yup.object({
  name: Yup.string()
    .matches(/^[a-zA-Z\s]*$/, "Only letters and spaces are allowed")
    // .min(6, "Name must be at least 6 characters")
    .required("Name is required"),
  contact: Yup.string()
    .matches(/^[0-9]{10}$/, "Contact number must be 10 digits")
    .required("Required"),
  country: Yup.string().required("Required"),
  address: Yup.string().required("Required"),
});

const Icons = ({ currentRow }) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [countries, setCountries] = useState();

  const [modalSettingIsOpen, setSettingIsOpen] = useState(false);

  // testing
  const [deleteModal, setDeleteModal] = useState(false);

  const [phoneCode, setPhoneCode] = useState("+92");

  const sendEditUserDetail = async (values) => {
    setIsLoading(true);
    const { country, contact, ...valuesWithoutNewPassword } = values;

    const modifiedValues = {
      ...valuesWithoutNewPassword,
      country_id: values.country,
      phone_number: values.contact,
    };
    console.log(modifiedValues, "fetchUserDetail");

    const requestBody = modifiedValues;
    await axios
      .post(
        process.env.REACT_APP_URL + "/update-user?user_id=" + currentRow?.id,

        requestBody,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then(function (response) {
        console.log(response);
        setIsOpen(false);
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const formik1 = useFormik({
    initialValues: {
      name: "",
      contact: "",
      country: "",
      address: "",
    },
    validationSchema: UserEditSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      // console.log("Submitted email:", values);
      sendEditUserDetail(values);
    },
  });

  const fetchAllCountriesDetails2 = async () => {
    await fetch(process.env.REACT_APP_URL + "/get-all-countries", {
      method: "GET",
      headers: new Headers({
        // Authorization: "Bearer " + token,
        Accept: "application/json",
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setCountries(data?.data); //get data in data
      });
  };

  const handleDeleteUser = async () => {
    console.log("user");

    await axios
      .delete(
        process.env.REACT_APP_URL + "/delete-user?user_id=" + currentRow?.id,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        console.log(`Deleted post with ID`);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setDeleteModal(false);
      });
  };

  useEffect(() => {
    fetchAllCountriesDetails2();
  }, []);

  useEffect(() => {
    setCountryCode();
  }, [formik1.values.country]);

  const setCountryCode = () => {
    if (formik1.values.country) {
      const country = countries?.find(
        (country) => country?.id == formik1.values.country
      );
      setPhoneCode(country?.phonecode);
      console.log(country, "code count");
    }
  };

  return (
    <>
      <BootstrapModal modalIsOpen={modalIsOpen} setIsOpen={setIsOpen}>
        <div style={{ width: "100%", textAlign: "end" }}>
          <AiOutlineCloseCircle
            style={{ color: "#fff", fontSize: "24px", cursor: "pointer" }}
            onClick={() => setIsOpen(false)}
          />
        </div>
        <form onSubmit={formik1.handleSubmit}>
          <SignUpWrapper>
            <p className="paragraph">Name</p>
            <InputField
              placeholder={"Enter an email"}
              style={{ marginBottom: "1.4rem" }}
              name="name"
              type="text"
              onChange={formik1.handleChange}
              value={formik1.values.name}
              error={formik1.touched.name && Boolean(formik1.errors.name)}
              helperText={formik1.touched.name && formik1.errors.name}
              onBlur={formik1.handleBlur}
            />

            <p className="paragraph">Country</p>

            <div style={{ position: "relative" }}>
              <select
                // name="countryId"
                name="country"
                className="form-control signup-select"
                // value={countryId}
                value={formik1.values.country}
                onChange={formik1.handleChange}
                // error={formik1.touched.country && Boolean(formik1.errors.country)}
                // helperText={formik1.touched.country && formik1.errors.country}
                // onChange={(e) => {
                //   // fetchAllStatesDetailsByCountry(e.target.value);
                //   // formik1.setFieldValue("countryId", e.target.value);
                // }}
                onBlur={formik1.handleBlur}
              >
                <option
                  value=""
                  disabled
                  selected
                  style={{ color: "rgb(118, 118, 118)" }}
                >
                  Select Country
                </option>
                {countries?.map((country) => {
                  return (
                    <option key={country?.id} value={country?.id}>
                      {country?.name}
                    </option>
                  );
                })}
              </select>
              {formik1.touched.country && Boolean(formik1?.errors?.country) ? (
                <div
                  style={{
                    position: "absolute",
                    bottom: "-18px",
                    left: "1px",
                    fontSize: "12px",
                    color: "red",
                  }}
                >
                  {formik1.touched.country && formik1?.errors.country}
                </div>
              ) : null}
            </div>

            <p className="paragraph">Contact No</p>
            {/* <InputField
              placeholder={"Enter contact no."}
              style={{ marginBottom: "1.4rem" }}
              name="contact"
              type="number"
              onChange={formik1.handleChange}
              value={formik1.values.contact}
              error={formik1.touched.contact && Boolean(formik1.errors.contact)}
              helperText={formik1.touched.contact && formik1.errors.contact}
              onBlur={formik1.handleBlur}
            /> */}

            <CountryCode
              placeholder={"Enter contact no."}
              style={{ marginBottom: "1.4rem" }}
              name="contact"
              type="number"
              onChange={formik1.handleChange}
              value={formik1.values.contact}
              error={formik1.touched.contact && Boolean(formik1.errors.contact)}
              helperText={formik1.touched.contact && formik1.errors.contact}
              onBlur={formik1.handleBlur}
              phoneCode={phoneCode}
            />

            <p className="paragraph">Address</p>
            <InputField
              placeholder={"Enter an Address"}
              style={{ marginBottom: "1.4rem" }}
              name="address"
              type="text"
              onChange={formik1.handleChange}
              value={formik1.values.address}
              error={formik1.touched.address && Boolean(formik1.errors.address)}
              helperText={formik1.touched.address && formik1.errors.address}
              onBlur={formik1.handleBlur}
            />
            <Button
              type="submit"
              loading={isLoading}
              si
              style={{ marginTop: "1.8rem" }}
            >
              Continue
            </Button>
          </SignUpWrapper>
        </form>
      </BootstrapModal>
      <BootstrapModal modalIsOpen={deleteModal} setIsOpen={setDeleteModal}>
        <div style={{ width: "100%", textAlign: "end" }}>
          <AiOutlineCloseCircle
            style={{ color: "#fff", fontSize: "24px", cursor: "pointer" }}
            onClick={() => setDeleteModal(false)}
          />
        </div>

        {/* Are you sure */}

        <h2
          style={{
            fontSize: "1.8rem",
            marginBottom: "10px",
            color: "#fff",
          }}
        >
          Are you sure you want to delete?
        </h2>
        {/* Display selectedItem data */}
        {/* ... */}
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "20px",
          }}
        >
          <button
            onClick={handleDeleteUser}
            style={{
              marginLeft: "10px",
              padding: "8px 16px",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
              backgroundColor: "#007bff",
              color: "white",
            }}
          >
            Confirm
          </button>
          <button
            onClick={() => setDeleteModal(false)}
            style={{
              marginLeft: "10px",
              padding: "8px 16px",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
              backgroundColor: "#ccc",
              color: "black",
            }}
          >
            Cancel
          </button>
        </div>

        {/* end Are you sure */}
      </BootstrapModal>

      <BootstrapModal
        modalIsOpen={modalSettingIsOpen}
        setIsOpen={setSettingIsOpen}
      >
        {/* roiweruoi */}
        <div style={{ width: "100%", textAlign: "end" }}>
          <AiOutlineCloseCircle
            style={{ color: "#fff", fontSize: "24px", cursor: "pointer" }}
            onClick={() => setSettingIsOpen(false)}
          />
        </div>
        {currentRow?.configuration !== null ? (
          <>
            <CheckboxWrapper>
              {console.log(currentRow, "currentRow")}
              <h2>Settings</h2>
              <h3>
                Blockchain <BsFillQuestionCircleFill />
              </h3>
              <PWrapper>{currentRow?.configuration?.trading_pair}</PWrapper>
              <h3>
                Token types
                <BsFillQuestionCircleFill />
              </h3>
              <PWrapper>{currentRow?.configuration?.token_type}</PWrapper>
              <h3>
                Industries <BsFillQuestionCircleFill />
              </h3>
              <PWrapper>{currentRow?.configuration?.industry}</PWrapper>
              <h3>
                Marketplaces <BsFillQuestionCircleFill />
              </h3>
              <PWrapper>{currentRow?.configuration?.market_place}</PWrapper>
              <h3>
                Negative emissions solutions
                <BsFillQuestionCircleFill />
              </h3>
              <PWrapper>{currentRow?.configuration?.industry}</PWrapper>
            </CheckboxWrapper>
          </>
        ) : (
          <>
            <h1 style={{ color: "#fff" }}>Configuration is not available</h1>
          </>
        )}
      </BootstrapModal>
      <IconWrapper>
        <AiTwotoneEdit
          onClick={() => {
            setIsOpen(true);
            console.log(currentRow);
          }}
          // onClick={() => console.log(currentRow)}
          style={{ cursor: "pointer" }}
        />{" "}
        |
        <AiTwotoneSetting
          // onClick={() => console.log(currentRow)}
          onClick={() => {
            setSettingIsOpen(true);
            console.log(currentRow);
          }}
          style={{ cursor: "pointer" }}
        />{" "}
        |
        <AiFillCloseCircle
          onClick={() => {
            setDeleteModal(true);
            console.log(currentRow);
          }}
          style={{ cursor: "pointer" }}
        />{" "}
        {/* <BiSolidTime /> */}
      </IconWrapper>
    </>
  );
};

const UserSignupSchema = Yup.object({
  name: Yup.string()
    .matches(/^[a-zA-Z\s]*$/, "Only letters and spaces are allowed")
    // .min(6, "Name must be at least 6 characters")
    .required("Name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .matches(
      /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
      "Invalid email format"
    )
    .required("Required"),
  contact: Yup.string()
    .matches(/^[0-9]{10}$/, "Contact number must be 10 digits")
    .required("Required"),
  country: Yup.string().required("Required"),
  address: Yup.string().required("Required"),
  password: Yup.string()
    .required("Required")
    .min(8, "Password must be at least 8 characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/,
      "Password must contain at least 1 lowercase letter, 1 uppercase letter, 1 digit, and 1 special character"
    ),

  newpassword: Yup.string()
    .required("Required")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

const Detail = () => {
  // const [modalOpen, setModalOpen] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [countries, setCountries] = useState();

  const [isdata, setIsData] = useState([]);
  const [customerdata, setCustomerData] = useState("");
  const [phoneCode, setPhoneCode] = useState("+92");

  const fetchAdminCustomer = async (pageNumber = 1) => {
    await fetch(
      // `https://dev4.instantsolutionslab.site/api/get-all-transaction-history?page=${activePage}`,
      process.env.REACT_APP_URL + "/get-all-users",
      {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
      .then((resp) => resp.json())
      .then((json) => {
        setIsData(json?.data?.data);
        // console.log(json?.data);
        setCustomerData(json?.data);
      });
  };

  const fetchAllCountriesDetails = async () => {
    await fetch(
      // "https://dev74.instantsolutionslab.site/api/get-all-home-countries",
      process.env.REACT_APP_URL + "/get-all-countries",
      {
        method: "GET",
        headers: new Headers({
          // Authorization: "Bearer " + token,
          Accept: "application/json",
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setCountries(data.data); //get data in data
      });
  };

  const sendUserDetail = (values) => {
    setIsLoading(true);
    const { newpassword, country, contact, ...valuesWithoutNewPassword } =
      values;

    const modifiedValues = {
      ...valuesWithoutNewPassword,
      country_id: values.country,
      phone_number: values.contact,
      password_confirmation: values.newpassword,
    };
    console.log(modifiedValues, "fetchUserDetail");

    // Testing
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("token")}`
    );

    var formdata = new FormData();
    formdata.append("name", modifiedValues?.name);

    formdata.append("email", modifiedValues?.email);
    formdata.append("password", modifiedValues?.password);
    formdata.append(
      "password_confirmation",
      modifiedValues?.password_confirmation
    );
    formdata.append("country_id", modifiedValues?.country_id);
    formdata.append("phone_number", modifiedValues?.phone_number);
    formdata.append("address", modifiedValues?.address);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      // redirect: "follow",
    };

    // fetch("http://localhost/pay-4-climate/api/create-user", requestOptions)
    fetch(process.env.REACT_APP_URL + "/create-user", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        setIsOpen(false);
      })
      .catch((error) => console.log("error", error))
      .finally(() => setIsLoading(false));

    // signup(modifiedValues)
    //   .then((res) => {
    //     if (res?.status == 200) {
    //       console.log(res?.data?.message);
    //       toast.success(res?.data?.message);
    //       setIsLogin(true);
    //     } else {
    //       console.log(res?.response?.data?.errors?.email[0]);
    //       toast.error(res?.response?.data?.errors?.email[0]);
    //     }
    //   })
    //   .catch((err) => console.log(err))
    //   .finally(() => {
    //     setIsLoading(false);
    //   });
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      contact: "",
      country: "",
      address: "",
      password: "",
      newpassword: "",
    },
    validationSchema: UserSignupSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      // console.log("Submitted email:", values);
      sendUserDetail(values);
    },
  });

  console.log(customerdata, "customerdata");

  function convertISOToCustomFormat(isoDate) {
    const date = new Date(isoDate);
    const day = date.getUTCDate().toString().padStart(2, "0");
    const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
    const year = date.getUTCFullYear();
    return `${day}/${month}/${year}`;
  }
  console.log(isdata, "isdataisdata");
  const data = isdata.map((tableItem) => ({
    // column1: convertISOToCustomFormat(tableItem.created_at),
    column1: `${tableItem?.name}`,
    column2: `${
      tableItem?.widget?.contribution_percentage
        ? tableItem?.widget?.contribution_percentage
        : 0
    } ETH`,
    column3: "1.5 ton",
    // column3: tableItem?.removed_tons + " ton",
    column4: convertISOToCustomFormat(tableItem.created_at),
    column5: <Icons currentRow={tableItem} />,
    // column5: <AiTwotoneEdit onClick={() => console.log(tableItem)} />,
    // column5: ({ row }) => <Icons row={row.original} />,
    // column5: ({ row }) => <Icons row={row.original} />,
  }));

  useEffect(() => {
    fetchAllCountriesDetails();
    fetchAdminCustomer();
  }, []);

  useEffect(() => {
    setCountryCode();
  }, [formik.values.country]);

  const setCountryCode = () => {
    if (formik.values.country) {
      const country = countries?.find(
        (country) => country?.id == formik.values.country
      );
      setPhoneCode(country?.phonecode);
      console.log(country, "code count");
    }
  };

  return (
    <>
      {/* <Modal isOpen={modalOpen} onClose={handleCloseModal}> */}
      <BootstrapModal modalIsOpen={modalIsOpen} setIsOpen={setIsOpen}>
        {/* <h2>Modal Content</h2>
        <p>This is the content of the modal.</p> */}
        <div style={{ width: "100%", textAlign: "end" }}>
          <AiOutlineCloseCircle
            style={{ color: "#fff", fontSize: "24px", cursor: "pointer" }}
            onClick={() => setIsOpen(false)}
          />
        </div>
        <form onSubmit={formik.handleSubmit}>
          <SignUpWrapper>
            <p className="paragraph">Name</p>
            <InputField
              placeholder={"Enter an email"}
              style={{ marginBottom: "1.4rem" }}
              name="name"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.name}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              onBlur={formik.handleBlur}
            />
            <p className="paragraph">Email</p>
            <InputField
              placeholder={"Enter an email"}
              style={{ marginBottom: "1.4rem" }}
              name="email"
              type="email"
              onChange={formik.handleChange}
              value={formik.values.email}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              onBlur={formik.handleBlur}
            />

            <p className="paragraph">Country</p>
            {/* <InputField
            placeholder={"Enter country"}
            style={{ marginBottom: "1.4rem" }}
            name="country"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.country}
            error={formik.touched.country && Boolean(formik.errors.country)}
            helperText={formik.touched.country && formik.errors.country}
            onBlur={formik.handleBlur}
          /> */}

            {/* <div style={{ position: "relative" }}> */}
            <div style={{ position: "relative" }}>
              <select
                // name="countryId"
                name="country"
                className="form-control signup-select"
                // value={countryId}
                value={formik.values.country}
                onChange={formik.handleChange}
                // error={formik.touched.country && Boolean(formik.errors.country)}
                // helperText={formik.touched.country && formik.errors.country}
                // onChange={(e) => {
                //   // fetchAllStatesDetailsByCountry(e.target.value);
                //   // formik.setFieldValue("countryId", e.target.value);
                // }}
                onBlur={formik.handleBlur}
              >
                <option
                  value=""
                  disabled
                  selected
                  style={{ color: "rgb(118, 118, 118)" }}
                >
                  Select Country
                </option>
                {countries?.map((country) => {
                  return (
                    <option key={country?.id} value={country?.id}>
                      {country?.name}
                    </option>
                  );
                })}
              </select>
              {formik.touched.country && Boolean(formik?.errors?.country) ? (
                <div
                  style={{
                    position: "absolute",
                    bottom: "-18px",
                    left: "1px",
                    fontSize: "12px",
                    color: "red",
                  }}
                >
                  {formik.touched.country && formik?.errors.country}
                </div>
              ) : null}
            </div>

            <p className="paragraph">Contact No</p>
            {/* <InputField
              placeholder={"Enter contact no."}
              style={{ marginBottom: "1.4rem" }}
              name="contact"
              type="number"
              onChange={formik.handleChange}
              value={formik.values.contact}
              error={formik.touched.contact && Boolean(formik.errors.contact)}
              helperText={formik.touched.contact && formik.errors.contact}
              onBlur={formik.handleBlur}
            /> */}

            <CountryCode
              placeholder={"Enter contact no."}
              name="contact"
              type="number"
              onChange={formik.handleChange}
              value={formik.values.contact}
              error={formik.touched.contact && Boolean(formik.errors.contact)}
              helperText={formik.touched.contact && formik.errors.contact}
              onBlur={formik.handleBlur}
              phoneCode={phoneCode}
            />

            <p className="paragraph">Address</p>
            <InputField
              placeholder={"Enter an Address"}
              style={{ marginBottom: "1.4rem" }}
              name="address"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.address}
              error={formik.touched.address && Boolean(formik.errors.address)}
              helperText={formik.touched.address && formik.errors.address}
              onBlur={formik.handleBlur}
            />
            <p className="paragraph">Password</p>
            <InputField
              placeholder={"Enter password"}
              type="password"
              name="password"
              onChange={formik.handleChange}
              value={formik.values.password}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              onBlur={formik.handleBlur}
            />
            <p className="paragraph" style={{ marginTop: "1.4rem" }}>
              Confirm Password
            </p>
            <InputField
              placeholder={"Enter confirm password"}
              type="password"
              name="newpassword"
              onChange={formik.handleChange}
              value={formik.values.newpassword}
              error={
                formik.touched.newpassword && Boolean(formik.errors.newpassword)
              }
              helperText={
                formik.touched.newpassword && formik.errors.newpassword
              }
              onBlur={formik.handleBlur}
            />
            <Button
              type="submit"
              loading={isLoading}
              si
              style={{ marginTop: "1.8rem" }}
            >
              Continue
            </Button>
          </SignUpWrapper>
        </form>
      </BootstrapModal>
      {/* </Modal> */}
      <CustomerWrapper>
        <h1>Customers</h1>
        <Button style={{ width: "fit-content" }} onClick={setIsOpen}>
          New Customer <AiOutlinePlus style={{ verticalAlign: "middle" }} />
        </Button>
      </CustomerWrapper>
      {/* <TableComponent columns={columns} data={data} /> */}
      <TableComponent
        columns={columns}
        data={data}
        activePage={customerdata?.current_page}
        totalItemsCount={customerdata?.total ?? 0}
        itemsCountPerPage={parseInt(fetchAdminCustomer?.per_page)}
        onChange={(pageNumber) => fetchAdminCustomer(pageNumber)}
      />
    </>
  );
};

export default Detail;
