// import React from "react";
// import EdiableListItem from "../EditableListItem/EditableListItem";

// const AdminSettings = () => {
//   return (
//     <div>
//       <EdiableListItem />
//     </div>
//   );
// };

// export default AdminSettings;
import React, { useEffect, useState } from "react";
import PageTitle from "../../component/PageTitle/PageTitle";
import styled from "styled-components";
import { BsFillQuestionCircleFill } from "react-icons/bs";
import EditableListItem from "../EditableListItem/EditableListItem";
import axios from "axios";

const HistoryWrapper = styled.div`
  h1 {
    color: #fff;
    font-size: 4rem;
  }
  h2 {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 0.2rem;
    font-size: 2rem;
    line-height: 1.5;
    margin-left: 1rem;
    color: #fff;
    margin-top: 1rem;
    svg {
      width: 1.8rem;
      height: 1.8rem;
    }
  }
`;

const AdminSettings = () => {
  const [todos, setTodos] = useState([]);
  const [blockchainValue, setBlockchainValue] = useState();
  const [isEditValue, setEditValue] = useState({ name: "", id: "" });

  // Token Types
  const [tokenData, setTokenData] = useState([]);
  const [TokenValue, setTokenValue] = useState();
  const [isTokenEditValue, setTokenEditValue] = useState({ name: "", id: "" });

  // fetch token data
  const fetchTokenData = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_URL + "/get-token-type",
        {
          headers: {
            Authorization: `Bearer ${localStorage?.getItem("token")}`,
          },
        }
      );
      setTokenData(response?.data?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleTokenAdd = async () => {
    const requestBody = {
      name: TokenValue,
    };

    axios
      .post(process.env.REACT_APP_URL + "/create-token-type", requestBody, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        fetchTokenData();
        setTokenValue("");
      })
      .catch((error) => {});
    console.log("it work");
  };

  const updateToken = async () => {
    try {
      const requestBody = {
        name: isTokenEditValue?.name,
      };

      const response = await axios.post(
        process.env.REACT_APP_URL +
          "/update-token-type/" +
          isTokenEditValue?.id,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      fetchTokenData();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // End Token Types

  const updateBlockchain = async () => {
    try {
      const requestBody = {
        name: isEditValue?.name,
      };

      const response = await axios.put(
        process.env.REACT_APP_URL + "/trading-pair/" + isEditValue?.id,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      fetchData();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_URL + "/trading-pair",
        {
          headers: {
            Authorization: `Bearer ${localStorage?.getItem("token")}`,
          },
        }
      );
      setTodos(response?.data?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleBlockChainAdd = async () => {
    const requestBody = {
      name: blockchainValue,
    };

    axios
      .post(process.env.REACT_APP_URL + "/trading-pair", requestBody, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        fetchData();
        setBlockchainValue("");
      })
      .catch((error) => {});
    console.log("it work");
  };

  // Industry
  const [industryData, setIndustryData] = useState([]);
  const [industryValue, setIndustryValue] = useState();
  const [isIndustryEditValue, setIndustryEditValue] = useState({
    name: "",
    id: "",
  });

  // fetch token data
  const fetchIndustryData = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_URL + "/industry",
        {
          headers: {
            Authorization: `Bearer ${localStorage?.getItem("token")}`,
          },
        }
      );
      setIndustryData(response?.data?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleIndustryAdd = async () => {
    const requestBody = {
      name: industryValue,
    };

    axios
      .post(process.env.REACT_APP_URL + "/industry", requestBody, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        fetchIndustryData();
        setIndustryValue("");
      })
      .catch((error) => {});
    console.log("it work");
  };

  const updateIndustry = async () => {
    try {
      const requestBody = {
        name: isIndustryEditValue?.name,
      };

      const response = await axios.put(
        process.env.REACT_APP_URL + "/industry/" + isIndustryEditValue?.id,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      fetchIndustryData();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // End Industry

  // Marketplaces

  const [marketData, setMarketData] = useState([]);
  const [marketValue, setMarketValue] = useState();
  const [isMarketEditValue, setMarketEditValue] = useState({
    name: "",
    id: "",
  });

  const fetchMarketData = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_URL + "/market-place",
        {
          headers: {
            Authorization: `Bearer ${localStorage?.getItem("token")}`,
          },
        }
      );
      setMarketData(response?.data?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleMarketAdd = async () => {
    const requestBody = {
      name: marketValue,
    };

    axios
      .post(process.env.REACT_APP_URL + "/market-place", requestBody, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        fetchMarketData();
        setMarketValue("");
      })
      .catch((error) => {});
    console.log("it work");
  };

  const updateMarket = async () => {
    try {
      const requestBody = {
        name: isMarketEditValue?.name,
      };

      const response = await axios.put(
        process.env.REACT_APP_URL + "/market-place/" + isMarketEditValue?.id,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      fetchMarketData();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // End Marketplaces

  // Negative emissions solutions

  const [emissionData, setEmissionData] = useState([]);
  const [emissionValue, setEmissionValue] = useState();
  const [isEmissionEditValue, setEmissionEditValue] = useState({
    name: "",
    id: "",
  });

  const fetchEmissionData = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_URL + "/retirement-type",
        {
          headers: {
            Authorization: `Bearer ${localStorage?.getItem("token")}`,
          },
        }
      );
      setEmissionData(response?.data?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleEmissionAdd = async () => {
    const requestBody = {
      name: emissionValue,
    };

    axios
      .post(process.env.REACT_APP_URL + "/retirement-type", requestBody, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        fetchEmissionData();
        setEmissionValue("");
      })
      .catch((error) => {});
    console.log("it work");
  };

  const updateEmission = async () => {
    try {
      const requestBody = {
        name: isEmissionEditValue?.name,
      };

      const response = await axios.put(
        process.env.REACT_APP_URL +
          "/retirement-type/" +
          isEmissionEditValue?.id,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      fetchEmissionData();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // End Negative emissions solutions

  useEffect(() => {
    fetchData();
    fetchTokenData();
    fetchIndustryData();
    fetchMarketData();
    fetchEmissionData();
  }, []);

  return (
    <div>
      <PageTitle title="Pay4Climate - history" />
      <HistoryWrapper>
        <h1>Settings</h1>

        <h2>
          Blockchain <BsFillQuestionCircleFill />
        </h2>
        <EditableListItem
          data={todos}
          value1={blockchainValue}
          setValue1={setBlockchainValue}
          placeholder={"Add Blockchain"}
          handleAddTodo={handleBlockChainAdd}
          isEditValue={isEditValue}
          setEditValue={setEditValue}
          updateAPI={updateBlockchain}
        />

        <h2>
          Token Types <BsFillQuestionCircleFill />
        </h2>
        <EditableListItem
          data={tokenData}
          value1={TokenValue}
          setValue1={setTokenValue}
          placeholder={"Add Token Type"}
          handleAddTodo={handleTokenAdd}
          isEditValue={isTokenEditValue}
          setEditValue={setTokenEditValue}
          updateAPI={updateToken}
        />
        <h2>
          Industries <BsFillQuestionCircleFill />
        </h2>
        <EditableListItem
          data={industryData}
          value1={industryValue}
          setValue1={setIndustryValue}
          placeholder={"Add Industry"}
          handleAddTodo={handleIndustryAdd}
          isEditValue={isIndustryEditValue}
          setEditValue={setIndustryEditValue}
          updateAPI={updateIndustry}
        />

        <h2>
          Marketplaces <BsFillQuestionCircleFill />
        </h2>
        <EditableListItem
          data={marketData}
          value1={marketValue}
          setValue1={setMarketValue}
          placeholder={"Add Marketplaces"}
          handleAddTodo={handleMarketAdd}
          isEditValue={isMarketEditValue}
          setEditValue={setMarketEditValue}
          updateAPI={updateMarket}
        />

        <h2>
          Negative emissions solutions <BsFillQuestionCircleFill />
        </h2>
        <EditableListItem
          data={emissionData}
          value1={emissionValue}
          setValue1={setEmissionValue}
          placeholder={"Add Emission"}
          handleAddTodo={handleEmissionAdd}
          isEditValue={isEmissionEditValue}
          setEditValue={setEmissionEditValue}
          updateAPI={updateEmission}
        />
      </HistoryWrapper>
    </div>
  );
};

export default AdminSettings;
