import React, { useState, useEffect } from "react";
import styled from "styled-components";

const WidgetWrapper = styled.div`
  p,
  div,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  span,
  button {
    font-family: ${(props) => props.$fontfamily} !important;
    color: ${(props) => props.$color3 || "#acf9e9"};
  }
`;

const Widgetview = () => {
  let bgColor = localStorage.getItem("backgroundColor");
  let fontFamily = localStorage.getItem("fontfamily");
  let textcolor = localStorage.getItem("textcolor");
  const [isShow, setIsShow] = useState(true);

  const handleCheck = () => {
    setIsShow(false);
  };

  const [data, setData] = useState();

  const getUserWidget = async () => {
    await fetch(`https://dev4.instantsolutionslab.site/api/get-my-widgets`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiYmFjZjg0NGUxYzhiM2M3Yjc0ODhmOTUzMDUyMTRiOTNiZmQ1NjE3NmNhMGRhZWE5NDc0NGQ3MzI3NzIwNjFmZDdhNjRkMmMxOTZiY2MyODUiLCJpYXQiOjE2OTI4OTc3NzMuMzUwOTk1LCJuYmYiOjE2OTI4OTc3NzMuMzUxMDA0LCJleHAiOjE3MjQ1MjAxNzMuMzQ2NDg2LCJzdWIiOiIzMCIsInNjb3BlcyI6W119.ZlP92p5RimC9bxolUIUOF9CZ44P1YHtIECcrJU6HfOxjZbw3Rx4zmxmGE0sY62rF3WWMDWLOu2Y7o_-ATvrINZiAet4BzEgP0YEGXVV3rhR6mO6GhCLgUQbEqhNMZV_Xl6LjSAj9R3yMizFRO64T4MBCETAG4juy-81RFUuwnlh9KbyKBvyfqPAtj8degVGnO3q6G0DnJn5LFOp4NF1b4I4gaXQbKN-NBm0xBz3LFzsBW4j1vCE2zSgLwKI9grpQ5pUby0gOR8Pm0Sje9jHdqwSJ4UgotFfdMfje3RV2u8Cu9EKZNcQ4mqQUSCoNU6oXYWSGZJ6NtgrUCDNT9IlEdTx1DZb--G30bGB_8CZiDwwQ-17_JoyWD86GiNBwnlcbCx5IKm4mOE89i23-go_YUY6OHXHUAZgquZEOWhNovDw1soEiI2ull9NLVuaV2nRfMQP6l0ClI73Q-IrROBSkek46366wGmEFKsVYOxIESjzYjmWKJmWKuIyrmUR2D3LJNXtZ5-OZx3v0vTEJrwt8rFzAzwryIGIW8CBGu_PIrvpH8uHvwJX45dxUPCt3E9RGEqkGaPJSS776N0G8MTT77GDAnQLy9Khe3ejXF6O4m0ipspfgqw5cpfoqXFIcjd1j_H1gOzaG-vKGuQTxHRNyTpX-KIvd8ZJTmV-ULqplG4c`,
      },
    })
      .then((resp) => resp.json())
      .then((json) => {
        setData(json?.data);
        // setRetirementTypeOption(json?.data?.data);
        console.log(json?.data, "json?.data");
        // setGetMyWidgetData(json?.data);
        // setBlockchainCurrency(json?.data);
      });
  };

  useEffect(() => {
    getUserWidget();
  }, []);

  return (
    data && (
      <WidgetWrapper
        // $color3={textcolor}
        $fontfamily={fontFamily}
        style={{
          // backgroundColor: data?.background_color,
          // backgroundColor: "#333d3e",
          backgroundColor: "rgb(51, 61, 62)",
          // color: data?.text_color,
          color: "#acf9e9 !important",
          fontFamily: data?.font,
        }}
      >
        {!isShow ? (
          <div
            className="newWrappper orquwe"
            style={{
              width: "100%",
              // background: bgColor,
              boxShadow: "4px 10px 12px 5px #000000b8",
              padding: "17px",
              borderRadius: "5px",
              color: textcolor,
            }}
            onClick={handleCheck}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <p style={{ width: "140px" }}>
                Since 20/11/2023 Microsoft helped to remove ...
              </p>
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: "35px",
                }}
              >
                123
              </p>
              <p
                style={{
                  fontWeight: "500",
                  fontSize: "12px",
                  width: "55px",
                  paddingLeft: "7px",
                }}
              >
                Tons of CO2
              </p>
            </div>
          </div>
        ) : (
          <div
            className="newWrappper orquwe"
            style={{
              width: "100%",
              background: bgColor,
              boxShadow: "4px 10px 12px 5px #000000b8",
              padding: "17px",
              borderRadius: "5px",
              color: textcolor,
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                borderBottom: "solid 1px #d6d6d6",
                paddingBottom: "20px",
              }}
            >
              <div
                style={{
                  width: "50%",
                  borderRight: "solid 1px #d6d6d6",
                  paddingRight: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <h1
                    style={{
                      marginRight: "10px",
                      fontSize: "38px",
                      marginBottom: "0",
                      fontWeight: "bold",
                    }}
                  >
                    123
                  </h1>
                  <p
                    style={{
                      marginBottom: "0",
                      fontSize: "12px",
                      position: "relative",
                      top: "8px",
                      width: "110px",
                      fontWeight: "bold",
                    }}
                  >
                    Tons of CO2 removed from atmosphere
                  </p>
                </div>
                <h1
                  style={{
                    fontSize: "38px",
                    margin: "0",
                    fontWeight: "bold",
                  }}
                >
                  {/* 22,210$ */}
                  12,300$
                </h1>
                <p
                  style={{
                    margin: "0",
                    fontWeight: "bold",
                  }}
                >
                  Total Contributions
                </p>
              </div>
              <div
                style={{
                  width: "50%",
                  paddingLeft: "15px",
                }}
              >
                <img
                  style={{
                    width: "199px",
                  }}
                  // src="https://firebasestorage.googleapis.com/v0/b/protreding.appspot.com/o/Frame%2017.png?alt=media&token=c2a8c624-6b12-43cb-af4f-3d21c897976e"
                  src="https://firebasestorage.googleapis.com/v0/b/protreding.appspot.com/o/image_2023_08_25T13_39_19_783Z.png?alt=media&token=f3370de2-58f0-4881-b7e1-2c946a28c52d"
                  alt="Image"
                />
              </div>
            </div>
            <div>
              <h1
                style={{
                  margin: "0",
                  fontSize: "20px",
                  fontWeight: "bold",
                }}
              >
                latest transactions
              </h1>
              <p>
                <span style={{ fontWeight: "bold" }}> 0.030 tons</span> removed
                on 25/08/2023 via{" "}
                <span style={{ fontWeight: "bold" }}>Toucan</span> | View on
                tronscan
              </p>
              <p>
                <span style={{ fontWeight: "bold" }}>0.015 tons</span> removed
                on 25/08/2023 via{" "}
                <span style={{ fontWeight: "bold" }}>Toucan</span> | View on
                tronscan
              </p>
              {/* <p>
                <span style={{ fontWeight: "bold" }}>1.5 tons</span> removed on
                12/11/2022 via <span style={{ fontWeight: "bold" }}>Aider</span>{" "}
                | View on etherscan
              </p> */}
              <a href="#">
                <span style={{ fontWeight: "bold" }}>view full history</span>
              </a>
            </div>
          </div>
        )}
      </WidgetWrapper>
    )
  );
};

export default Widgetview;
