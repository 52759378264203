import React, { useState } from "react";
import styled from "styled-components";

const HeaderContainer = styled.header`
  /* ... (same as before) */
`;

const ToggleButton = styled.button`
  background: none;
  border: none;
  color: white;
  font-size: 1rem;
  cursor: pointer;
`;

const Logo = styled.h1`
  font-size: 1.5rem;
`;

const Nav = styled.nav`
  ul {
    list-style: none;
    display: flex;
    gap: 1rem;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    li {
      cursor: pointer;
    }
  }
`;

const Header = () => {
  const [isNavVisible, setIsNavVisible] = useState(false);

  const toggleNavVisibility = () => {
    setIsNavVisible(!isNavVisible);
  };

  return (
    <HeaderContainer>
      <Logo>My Logo</Logo>
      <ToggleButton onClick={toggleNavVisibility}>
        {isNavVisible ? "Hide" : "Show"} Menu
      </ToggleButton>
      <Nav style={{ display: isNavVisible ? "block" : "none" }}>
        <ul>
          <li>Home</li>
          <li>About</li>
          <li>Contact</li>
        </ul>
      </Nav>
    </HeaderContainer>
  );
};

export default Header;
