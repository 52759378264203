import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import UserDasbboard from "../pages/user/UserDasbboard";

import NotFound from "../component/NotFund/NotFound";
import { ToastContainer } from "react-toastify";

const UserRoutes = () => {
  return (
    <div>
      <Routes>
        <Route exact path="/" element={<Navigate to="/UserDasbboard" />} />
        <Route exact path="/user/dashboard" element={<UserDasbboard />} />

        {/* <Route path="*" element={<NotFound />} /> */}
        <Route path="*" element={<Navigate to="/user/dashboard" />} />
        {/* <Route path="*" element={<Navigate to="/NotFound" />} /> */}
      </Routes>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
};

export default UserRoutes;
