// Set Data in Local Storage
export function setDataInLocalStorage(key, value) {
  window.localStorage.setItem(key, value);
}

export function getDataFromLocalStorage(key) {
  return localStorage?.getItem(key);
}

export function deleteDataFromLocalStorage(key) {
  window.localStorage?.removeItem(key);
}
