import React, { useEffect, useState } from "react";
import styled from "styled-components";
import InputField from "../../component/InputField/InputField";
import PercentageWithButtons from "../../component/PercentageWithButtons/PercentageWithButtons";
import { BsFillQuestionCircleFill } from "react-icons/bs";
import ReactSelect from "../../component/ReactSelect/ReactSelect";
import DynamicSelect from "../../component/ReactSelect/ReactSelect";
import ColorPickerComponent from "../../component/ColorPickerComponent/ColorPickerComponent";
import Button from "../../component/Button/Button";
import DynamicCopyToClipboard from "../../component/DynamicCopyToClipboard/DynamicCopyToClipboard";
import CopyToClipboard from "react-copy-to-clipboard";
import url from "../../assets/auth/Frame 17.png";
import PageTitle from "../../component/PageTitle/PageTitle";
import debounce from "lodash/debounce";
import { toast } from "react-toastify";
import axios from "axios";
import { BsArrowUpRight } from "react-icons/bs";
// import TableComponent from "../../TableComponent/TableComponent";
import TableComponent from "../../component/TableComponent/TableComponent";

const wrapperStyles = `
background:yellow;
`;

const columns = [
  { Header: "Name ", accessor: "column1" },
  { Header: "Email", accessor: "column2" },
  { Header: "Country", accessor: "column3" },
  // { Header: "Invoice", accessor: "column4" },
  { Header: "Invoice id", accessor: "column4" },
  { Header: "Status", accessor: "column5" },
  { Header: "Amount", accessor: "column6" },
  // Add more columns as needed
];

// const wrapperStyles = {
//   background_color: "red",
// };
var abc = "#fff";

const rawHTML = `
<style>
  :root {
    --bg-color:${abc};
  }
</style>
<div class="newWrappper"  style="${wrapperStyles}
width: 100%;
box-shadow: 4px 10px 12px 5px :var(--bg-color);
padding: 17px;
border-radius: 5px;">
  <div style="    display: flex;
  align-items: center;
  border-bottom: solid 1px #d6d6d6;
  padding-bottom: 20px;">
    <div style="width: 50%;
    border-right: solid 1px #d6d6d6;
    padding-right: 10px;">
        <div style="display: flex;
        align-items: center;">
            <h1 style="    margin-right: 10px;font-size: 38px;margin-bottom: 0;font-weight: bold">123</h1>
            <p style="    margin-bottom: 0;
            font-size: 12px;
            position: relative;
            top: 8px;
            width: 110px;font-weight: bold">Tons of CO2 removed from atmosphere</p>
        </div>
        <h1 style="font-size: 38px;    margin: 0;font-weight: bold">12,300$</h1>
        <p style="    margin: 0;font-weight: bold">Total Contributions</p>
    </div>
    <div style="    width: 50%;
    padding-left: 15px;">
    <img style="width: 225px;" src="https://firebasestorage.googleapis.com/v0/b/protreding.appspot.com/o/image_2023_08_25T13_39_19_783Z.png?alt=media&token=f3370de2-58f0-4881-b7e1-2c946a28c52d">
    </div>
  </div>
  <div>
    <h1 style="margin: 0;
    font-size: 20px;font-weight: bold">latest transactions</h1>
    <p><span style="font-weight: bold">0.030 tons</span> removed on 25/08/2023 via <span style="font-weight: bold">Toucan</span> | View on tronscan</p>
    <p><span style="font-weight: bold">0.015 tons </span> removed on 25/08/2023 via <span style="font-weight: bold">Toucan</span> | View on tronscan</p>


    <a href="#"><span style="font-weight: bold">view full history</span></a>
  </div>
`;

const ProfileWrapper = styled.div`
  h1 {
    color: #fff;
    font-size: 4rem;
  }
  h2 {
    font-size: 2rem;
    color: #fff;
    line-height: 1.5;
    margin-bottom: 1rem;
  }
  .pspwrapper {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    padding-bottom: 1.2rem;
    border-bottom: 2px solid rgba(111, 111, 111, 0.4);
    p {
      font-size: 1.6rem;
      font-weight: 700;
      color: #fff;
    }
  }
  .namewrapper {
    // display: flex;
    align-items: center;
    gap: 0.8rem;
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
    // border-bottom: 2px solid #6f6f6f;
    border-bottom: 2px solid rgba(111, 111, 111, 0.4);
    p {
      font-size: 1.6rem;
      font-weight: 700;
      color: #fff;
      padding-bottom: 0.8rem;
    }
  }
  .contributionwrapper {
    // display: flex;
    align-items: center;
    gap: 0.8rem;
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
    // border-bottom: 2px solid #6f6f6f;
    border-bottom: 2px solid rgba(111, 111, 111, 0.4);
    .heading {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin-bottom: 1rem !important;
    }
    p {
      font-size: 1.6rem;
      font-weight: 700;
      color: #fff;
      padding-bottom: 0.8rem;
    }
  }

  .publicwrapper {
    // display: flex;
    align-items: center;
    gap: 0.8rem;
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
    // border-bottom: 2px solid #6f6f6f;
    border-bottom: 2px solid rgba(111, 111, 111, 0.4);
    .wrapper {
      display: flex;
      gap: 0.8rem;
      align-items: center;
      p {
        padding-bottom: 0;
      }
    }
    p {
      font-size: 1.6rem;
      font-weight: 700;
      color: #fff;
      padding-bottom: 0.8rem;
    }
  }
  .widgetwrapper {
    // display: flex;
    align-items: center;
    gap: 0.8rem;
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
    // border-bottom: 2px solid #6f6f6f;
    // border-bottom: 2px solid rgba(111, 111, 111, 0.4);
    .heading {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin-bottom: 1rem;
    }
    p {
      font-size: 1.8rem;
      font-weight: 400;
      color: #fff;

      // padding-bottom: 0.8rem;
    }
    .wrapper{
      background:red;
      display:grid;
      grid-template-columns:repeat(2,1fr);
      // display:flex;
      textarea{
        width:100%;
        height:200px
        background:#000;
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10 and IE 11 */
        user-select: none !important; /* Standard syntax */
      }
    
    }
  }

  
  .textareawrapper{
    // background-color:#000;
    // display:grid;
    // grid-template-columns:repeat(2,1fr);
    display:flex;
    align-items:flex-start;
    gap:1.6rem;
    .textareawrapper-item{
      width:100%;


      .copy-wrapper{

        position:relative;
        .copy-clipboard{
          position:absolute;
          bottom:20px;
          right:20px;
          width:auto;

        }
      }

     

    }
    .textarea{
      // background-color:green;
      background-color:#000;
      outline:none;
      padding:1.4rem;
      width:100%;
       color:#fff;
    }
    .preview{
      width:100%;
      height:400px;
      background:#000;
      overflow:auto;
      padding:1.4rem;
      border: 1px solid #6f6f6f;  
    }
    .widget_item{
      // background-color:red;
      width:100%;
      display:flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom:2rem;
    }

  }
  .newWrappper{
    // z-index:-1;
    background-color: #fff;
    background:${(props) => props.$color1} !important;
    p,div,h1,h2,h3,h4,h5,h6,span,button {
      font-family: ${(props) => props.$fontfamily} !important;
      color:${(props) => props.$color3};
      
  }
  .widgetClass{
    // z-index:-1;
    background:${(props) => props.$color1} !important;
    p,div,h1,h2,h3,h4,h5,h6,span,button {
      font-family: ${(props) => props.$fontfamily} !important;
      color:${(props) => props.$color3};
    }
    // position:relative;
    // .preview-text{
    //   position:absolute;
    //   top:50%;
    //   left:50%;
    //   transform:translate(-50%,-50%);
    //   color:#fff;
    // }

  }

  }
  .testtin{
    position:relative ;
    // .preview-text{
    //   position:absolute;
    //   top:50%;
    //   left:50%;
    //   transform:translate(-50%,-50%);
    //   color:#fff;
    // }
    .share-button{
      position:absolute;
      top:10px;
      right:10px;
      width:auto; 
      color:#fff;
    }
  }


`;

const options = [
  { value: "option1", label: "Floating Panel" },
  { value: "option2", label: "Floating Panel" },
  { value: "option3", label: "Floating Panel" },
];
const options2 = [
  { value: "roboto", label: "Roboto" },
  { value: "georgia", label: "Georgia" },
  { value: "verdana", label: "Verdana" },
];

const Profile = () => {
  const [isHtml, setHtml] = useState(rawHTML);
  const [textValue, setTextValue] = useState("");

  const [isdata, setIsData] = useState([]);
  // const [selectedOption, setSelectedOption] = useState("Floating Panel");
  const [selectedOption, setSelectedOption] = useState({
    value: "option1",
    label: "Floating Panel",
  });
  const [selectFont, setSelectFont] = useState({
    value: "roboto",
    label: "Roboto",
  });
  const [color1, setColor1] = useState("#ffffff");
  const [color2, setColor2] = useState("#000000");
  const [color3, setColor3] = useState("#000000");
  const [copied, setCopied] = useState(false);
  const [percentageV, setPercentageV] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [slugName, setSlugName] = useState("");
  const [showSlugResponse, setShowSlugResponse] = useState(false);
  const [getMyWidgetData, setGetMyWidgetData] = useState("");
  const [testPercentage, setTestPercentage] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const [isLoading3, setIsLoading3] = useState(false);

  const getUserWidget = async () => {
    await fetch(`https://dev4.instantsolutionslab.site/api/get-my-widgets`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((resp) => resp.json())
      .then((json) => {
        // setData(json?.data?.data);
        // setRetirementTypeOption(json?.data?.data);
        console.log(json?.data, "json?.data");
        setGetMyWidgetData(json?.data);
        // setBlockchainCurrency(json?.data);
      });
  };

  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };
  const handleFontChange = (selectedOption) => {
    setSelectFont(selectedOption);
  };

  const handleSubmit = () => {
    setHtml(textValue);
  };
  const handleChange = (e) => {
    setTextValue(e.target.value);
  };

  const handleCopy = () => {
    setCopied(true);

    // Reset the "copied" state after a certain duration
    // setTimeout(() => {
    setCopied(false);
    // }, 20000);
  };

  const checkSlug = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("token")}`
    );

    var formdata = new FormData();
    formdata.append("slug", slugName);

    var requestOptions = {
      method: "POST",
      body: formdata,
      headers: myHeaders,
      // redirect: "manual",
    };

    await fetch(
      "https://dev4.instantsolutionslab.site/api/check-my-widget-slug",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        setShowSlugResponse(result?.message);
      })
      .catch((error) => console.log("error", error));
  };

  const delayedCheckSlug = debounce(() => {
    // Call the checkSlug function here
    // checkSlug(slug);
    checkSlug();
  }, 1000); // Adjust the delay time as needed

  const handleSlugName = (e) => {
    console.log(e.target.value, "e.target.value");
    setSlugName(e.target.value);
    delayedCheckSlug();

    // debounce((slug) => {
    //   checkSlug();
    // }, 300);
  };

  // update my widget

  const updateWidgetApi = () => {
    setIsLoading(true);

    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("token")}`
    );

    var formdata = new FormData();
    formdata.append("widget", isHtml);
    formdata.append("background_color", color1);
    formdata.append("secondary_color", color2);
    formdata.append("text_color", color3);
    formdata.append("font", selectFont?.value);
    formdata.append("contribution_percentage", percentage);
    formdata.append("slug", slugName);
    formdata.append("type", selectedOption?.label);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      "https://dev4.instantsolutionslab.site/api/update-my-widgets",
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        console.log(result);
        toast.success("Widget updated successfully");
      })
      .catch((error) => console.log("error", error))
      .finally((res) => {
        setIsLoading(false);
      });
  };

  // console.log(color1, color2, color3, "color3");
  localStorage.setItem("backgroundColor", color1);
  localStorage.setItem("textcolor", color3);
  localStorage.setItem("fontfamily", setSelectFont?.label);

  const handleUpdateWidget = () => {
    console.log("testing");
    if (isHtml && selectFont?.value && slugName && selectedOption?.label) {
      updateWidgetApi();
    } else {
      toast.error("Kindly fill all the field");
    }
    console.log(
      // isHtml,
      // color1,
      // color2,
      // color3,
      selectFont?.value,
      // percentageV,
      slugName,
      selectedOption?.label
    );
  };

  useEffect(() => {
    getUserWidget();
  }, []);
  useEffect(() => {
    console.log(getMyWidgetData, "getMyWidgetData");
    // setHtml(getMyWidgetData?.widget || rawHTML);
    setColor1(getMyWidgetData?.background_color || "#ffffff");
    setColor2(getMyWidgetData?.secondary_color || "#000000");
    setColor3(getMyWidgetData?.text_color || "#000000");
    // setSelectFont(getMyWidgetData?.font || "roboto");
    setSelectFont({
      value: getMyWidgetData?.font || "roboto",
      label: getMyWidgetData?.font || "Roboto",
    });
    // setPercentageV(getMyWidgetData?.contribution_percentage || 0);
    // setPercentageV(getMyWidgetData?.contribution_percentage || 0);
    console.log(
      getMyWidgetData?.contribution_percentage,
      "getMyWidgetData?.contribution_percentage"
    );
    setPercentage(Number(getMyWidgetData?.contribution_percentage) || 0);
    // setTestPercentage(getMyWidgetData?.contribution_percentage);
    setSlugName(getMyWidgetData?.slug || "");
    // setSelectedOption(getMyWidgetData?.type || "Floating Panel");
    setSelectedOption(
      {
        value: "option1",
        label: "Floating Panel",
      } || "Floating Panel"
    );
  }, [getMyWidgetData]);

  const handleMarketPlace = async () => {
    setIsLoading3(true);

    // setTimeout(() => {

    //   setIsLoading3(false);
    // }, 4000);
    try {
      const requestBody = new FormData();
      requestBody.append("card_number", "4242424242424242");
      requestBody.append("exp_month", "12");
      requestBody.append("exp_year", "25");
      requestBody.append("cvc", "123");
      // const requestBody = {
      //   card_number: "4242424242424242",
      //   exp_month: "12",
      //   exp_year: "25",
      //   cvc: "123",
      // };

      const response = await axios.post(
        `https://dev4.instantsolutionslab.site/api/connect-with-market-place`,
        null,

        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      // toast.success(response?.data?.message);
      setIsLoading3(false);
      console.log(response);
      // setIsData(json?.data?.data);
      console.log(response?.data?.data);

      toast.success("Toucan MarketPlace has been created");
      // setIsData(response);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading3(false);
    }
  };

  const Icons = (tableItem) => {
    // const navigate = useNavigate();
    // console.log(tableItem, "tableItem");
    const newUrl = tableItem?.tableItem?.hosted_invoice_url;
    console.log(newUrl, "tableItem");

    return (
      // <IconWrapper onClick={() => window?.location?.href("https://google.com")}>
      // <IconWrapper>
      <a href={newUrl} target="_blank">
        View Invoice <BsArrowUpRight />
      </a>
      // </IconWrapper>
    );
  };

  const data = isdata.map((tableItem) => ({
    // column1: convertISOToCustomFormat(tableItem.created_at),
    column1: `${tableItem?.customer_name}`,
    column2: `${tableItem?.customer_email}`,
    column3: `${tableItem?.account_country}`,
    // column4: <Icons tableItem={tableItem} />,s
    column4: `${tableItem?.id}`,
    column5: `${tableItem?.status}`,
    column6: `${Number(tableItem?.total) / 100} USD`,
    // column4: convertISOToCustomFormat(tableItem.created_at),
    // column5: <Icons currentRow={tableItem} />,
    // column5: <AiTwotoneEdit onClick={() => console.log(tableItem)} />,
    // column5: ({ row }) => <Icons row={row.original} />,
    // column5: ({ row }) => <Icons row={row.original} />,
  }));

  const getMyInvoice = async () => {
    try {
      const response = await axios.get(
        "https://dev4.instantsolutionslab.site/api/get-my-invoices",
        {
          headers: {
            Authorization: `Bearer ${localStorage?.getItem("token")}`,
          },
        }
      );
      console.log(response?.data?.data);
      setIsData(response?.data?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getMyInvoice();
  }, []);

  return (
    <>
      <PageTitle title="Pay4Climate - profile" />
      <ProfileWrapper
        $color1={color1}
        $color3={color3}
        $fontfamily={selectFont?.label}
      >
        <h1>Profile</h1>
        <h2>Configure</h2>
        {/* <div className="pspwrapper">
          <p> Your PSP:</p>
          <InputField
            placeholder={"Fidomoney"}
            style={{ width: "150%", height: "36px" }}
            disabled
          />
        </div> */}
        <div className="namewrapper">
          <p> Name:</p>
          <InputField
            placeholder={"Microsoft"}
            style={{ width: "30%", height: "36px" }}
            value={slugName}
            onChange={handleSlugName}
          />
          {showSlugResponse && (
            <>
              <span style={{ color: "#fff", fontSize: "14px" }}>
                {showSlugResponse}
              </span>
            </>
          )}
        </div>

        <div className="contributionwrapper">
          <Button
            onClick={handleMarketPlace}
            style={{ width: "18%" }}
            loading={isLoading3}
          >
            Connect With MarketPlace
          </Button>
        </div>

        {console.log(isdata, "isdata")}
        {isdata.length > 0 && (
          <TableComponent
            columns={columns}
            data={data}
            // activePage={isdata?.current_page}
            // totalItemsCount={isdata?.total ?? 0}
            // itemsCountPerPage={parseInt(isdata?.per_page)}
            // onChange={(pageNumber) => getMyInvoice(pageNumber)}
          />
        )}

        <div className="contributionwrapper">
          <p className="heading">
            Contribution Percentage: <BsFillQuestionCircleFill />
          </p>
          <PercentageWithButtons
            // setPercentageV={setPercentageV}
            // testPercentage={testPercentage}
            percentage={percentage}
            setPercentage={setPercentage}
          />
        </div>
        <div className="publicwrapper">
          <p> Profile page:</p>
          <div className="wrapper">
            {/* <p>https://public.pay4climate.org/</p> */}
            <p>https://dev4.instantsolutionslab.site/api/</p>
            <InputField
              placeholder={"Microsoft"}
              style={{ width: "150%", height: "36px" }}
              disabled
              value={slugName}
            />
          </div>
        </div>
        <div className="widgetwrapper">
          <p className="heading">
            {" "}
            Widget: <BsFillQuestionCircleFill />
          </p>

          <div className="textareawrapper">
            <div className="textareawrapper-item">
              <div className="copy-wrapper">
                <textarea
                  className="textarea"
                  rows="15"
                  cols="50"
                  onChange={handleChange}
                  placeholder="Enter your markup"
                  disabled
                  value={isHtml}
                >
                  {textValue}
                </textarea>
                <CopyToClipboard text={isHtml}>
                  {/* <CopyToClipboard text={navigator.clipboard.writeText(isHtml)}> */}
                  <Button className="copy-clipboard" onClick={handleCopy}>
                    copy
                    {/* {copied ? "Copied!" : "Copy to Clipboard"} */}
                  </Button>
                </CopyToClipboard>

                {/* <DynamicCopyToClipboard
                textToCopy={textValue}
                copy={copied}
                setCopied={setCopied}
              /> */}
              </div>

              {/* <button onClick={handleSubmit} style={{ marginBottom: "2rem" }}>
              Submit
            </button> */}
              {/* <Button
              onClick={handleSubmit}
              style={{
                marginBottom: "2rem",
                width: "auto",
                marginTop: "2rem",
              }}
            >
              Submit
            </Button> */}

              <div className="widget_item" style={{ marginTop: "2rem" }}>
                <p>Font</p>
                <DynamicSelect
                  options={options2}
                  onChange={handleFontChange} // value={selectedOption}
                  defaultValue={selectFont}
                />
              </div>
              <div className="widget_item">
                <p>Background Color</p>
                <ColorPickerComponent color={color1} setColor={setColor1} />
              </div>
              <div className="widget_item">
                <p>Secondary Color</p>
                <ColorPickerComponent color={color2} setColor={setColor2} />
              </div>
              <div className="widget_item">
                <p>Text Color</p>
                <ColorPickerComponent color={color3} setColor={setColor3} />
              </div>
              <div className="widget_item widgetClass">
                <p>Widget type</p>
                <DynamicSelect
                  options={options}
                  onChange={handleSelectChange}
                  // value={selectedOption}
                  defaultValue={{ label: "Floating Panel", value: 0 }}
                />
              </div>
              <Button
                style={{ width: "50%" }}
                loading={isLoading}
                onClick={handleUpdateWidget}
              >
                Update Widget
              </Button>
            </div>

            <div className="preview testtin">
              <div dangerouslySetInnerHTML={{ __html: isHtml }}></div>
              {isHtml && (
                <>
                  <Button className="share-button">
                    Share on social networks
                  </Button>
                </>
              )}
              {!isHtml && (
                <>
                  <p className="preview-text">Preview here</p>
                </>
              )}
            </div>
          </div>
        </div>
      </ProfileWrapper>
    </>
  );
};

export default Profile;
