import React, { useState } from "react";
import AuthLayout from "../AuthLayout/AuthLayout";
import Image1 from "../../assets/auth/image1.png";
import InputField from "../InputField/InputField";
import Button from "../Button/Button";
import styled from "styled-components";
import Logo from "../../assets/Pay4Climate.png";
import { toast } from "react-toastify";

import Login from "../Login/Login";
import Signup from "../Signup/Signup";

const SignInWrapper = styled.div`
  position: relative;
  background-color: #091512;
  // height: 100vh;
  height: 100%;

  .footer-heading {
    position: absolute;
    bottom: 0;
    right: 50%;
    transform: translate(50%, -50%);
    text-align: center;
    color: #fff;
    font-size: 1.4rem;
  }
  .full-page {
    max-width: 600px;
    width: 100%;
    margin: auto;
    // height: 100%;
    height: ${(props) => (props.isLogin ? "100vh" : `auto`)};
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .wrapper {
    height: auto;
    border: 2px solid #6f6f6f;
    width: 100%;
    // padding: 3rem;
    background: #060e0c;
    border-radius: 5px;
    overflow-y: auto;
    margin: 10px;

    padding: 1rem 3rem 3rem 3rem;
  }
`;
const TabsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  text-align: center;
  margin-bottom: 2.4rem;
  border-radius: 5px;
  overflow: auto;
  border: 2px solid #6f6f6f;

  h1 {
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
  }
`;

const Signin = () => {
  const [isLogin, setIsLogin] = useState(true);
  const handleLogin = () => {
    setIsLogin(true);
  };
  const handleSignup = () => {
    setIsLogin(false);
  };
  return (
    <SignInWrapper isLogin={isLogin}>
      <div className="full-page">
        <div className="wrapper">
          <div className="" style={{ textAlign: "center" }}>
            <img src={Logo} style={{ maxWidth: "380px", width: "100%" }} />
          </div>
          <TabsWrapper>
            <h1
              onClick={handleLogin}
              style={{
                background: isLogin
                  ? "linear-gradient(261.78deg, #beee62 -1.89%, #68cec7 99.57%)"
                  : "",
                color: !isLogin ? "#fff" : "",
              }}
            >
              Login
            </h1>
            <h1
              onClick={handleSignup}
              style={{
                background: !isLogin
                  ? "linear-gradient(261.78deg, #beee62 -1.89%, #68cec7 99.57%)"
                  : "",
                color: isLogin ? "#fff" : "",
              }}
            >
              Signup
            </h1>
          </TabsWrapper>
          {isLogin && <Login />}
          {!isLogin && <Signup setIsLogin={setIsLogin} />}
        </div>
      </div>
      {isLogin && (
        <p className="footer-heading">
          The 1st paytech/climatetech solution powered by AI and blockchain
          providing an easy, transparent and secure process for financial
          institutions and their business clients to measure and mitigate the
          ESG impact of their strategic initiatives.
        </p>
      )}
    </SignInWrapper>
  );
};

export default Signin;
