import React, { useState } from "react";
import AuthLayout from "../AuthLayout/AuthLayout";
import Image1 from "../../assets/auth/image1.png";
import InputField from "../InputField/InputField";
import Button from "../Button/Button";
import styled from "styled-components";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import Logo from "../../assets/Pay4Climate.png";
import PageTitle from "../PageTitle/PageTitle";
import { Formik, Form, useFormik } from "formik";
import * as Yup from "yup";
import { forgotPassword } from "../../services/auth";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

// import styled from "@emotion/styled";

const SignInWrapper = styled.div`
  background-color: #091512;

  .full-page {
    max-width: 400px;
    width: 100%;
    // background-color: red;
    margin: auto;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .wrapper {
    height: auto;
    border: 2px solid #6f6f6f;
    width: 100%;
    padding: 3rem;

    background: #060e0c;
    border-radius: 5px;
    overflow-y: auto;
    margin: 10px;
    padding: 1rem 3rem 3rem 3rem;
  }
  .email-wrapper {
    .paragraph {
      font-size: 16px;
      line-height: 1.5;
      color: #fff;
    }
  }
`;
const TabsWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  text-align: center;
  margin-bottom: 2.4rem;
  overflow: hidden;
  h1 {
    color: #fff;

    padding: 5px;
    border-radius: 5px;
    width: 100%;
    font-size: 2.4rem;
  }
`;

const ForgetPasswordSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .matches(
      /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
      "Invalid email format"
    )
    .required("Required"),
});
const ForgetPassword = () => {
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const handleForgotPassword = (values) => {
    setIsLoading(true);
    console.log(values);
    forgotPassword(values)
      .then((res) => {
        // console.log(res);
        if (res?.status == 200) {
          console.log(res?.data?.message);
          toast.success(res?.data?.message);
          navigate("/verification");
        } else {
          console.log(res?.response?.data?.errors?.email[0]);
          toast.error(res?.response?.data?.errors?.email[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const formik = useFormik({
    initialValues: { email: "" },
    validationSchema: ForgetPasswordSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      // console.log("Submitted email:", values.email);
      handleForgotPassword(values);
    },
  });

  return (
    <>
      <PageTitle title="Pay4Climate - forgot password" />
      <form onSubmit={formik.handleSubmit}>
        <SignInWrapper>
          <div className="full-page">
            <div className="wrapper">
              <div className="" style={{ textAlign: "center" }}>
                <img src={Logo} style={{ maxWidth: "380px", width: "100%" }} />
              </div>
              <TabsWrapper>
                <h1>Forgot Password</h1>
              </TabsWrapper>
              <div className="email-wrapper">
                <p className="paragraph">Email</p>
                <InputField
                  placeholder={"Enter an email"}
                  style={{ marginBottom: "1.4rem" }}
                  name="email"
                  type="email"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  onBlur={formik.handleBlur}
                  // error={formik.touched.email && Boolean(formik.errors.email)}
                />
                {/* <Link to="/verification"> */}
                {/* <Button type="submit" onClick={formik.handleSubmit}> */}
                <Button type="submit" loading={isLoading}>
                  Continue
                </Button>
                {/* </Link> */}
              </div>
            </div>
          </div>
        </SignInWrapper>
      </form>
    </>
  );
};

export default ForgetPassword;
