import { createTheme } from "@mui/material/styles";
// import Typography from "@theme/Typography/Typography";

// import { ButtonLight } from "@theme/Button/Button";
import { ButtonLight } from "./Button/Button";

import { InputFieldLight } from "./InputField/InputField";
// import InputFieldLight from "./InputField/InputField";
// import { InputFieldLight } from "./InputField/InputField";

// console.log(DropdownLight)
// console.log(InputFieldLight)

const LightTheme = createTheme({
  palette: {
    primary: {
      main: "#ffffff",
      type: "light",
    },
  },
  // typography: Typography,
  components: {
    MuiButton: ButtonLight,
    MuiTextField: InputFieldLight,
  },
});

export default LightTheme;
