import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import PropTypes from "prop-types";
// import { combinedTheme } from "@theme/index";
import { combinedTheme } from "../../theme";
import { useSelector } from "react-redux";

const ThemeWrapper = ({ children }) => {
  const selectedTheme = useSelector((state) => state.general.theme);
  return (
    <ThemeProvider theme={combinedTheme[selectedTheme]}>
      {children}
    </ThemeProvider>
  );
};

ThemeWrapper.propTypes = {
  children: PropTypes.node,
};

export default ThemeWrapper;
