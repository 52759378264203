import { sumBy } from "lodash";
import React from "react";
import styled from "styled-components";

const CountryWrapper = styled.input`
  padding: 10px 20px;
  color: #fff;
  border: none;
  border-radius: 5px;

  // width: 100%;
  width: auto;
  background: #060e0c;

  &:focus-visible {
    outline: none;
  }
`;

// const HelperText = styled.span`
//   // Your HelperText styles
// `;
const HelperText = styled.p`
  color: red;
  // color: ${(props) => props.$istext && "red"};
  font-size: 1.2rem;
  position: absolute;
  bottom: -15px;
  left: 2px;
`;

const Wrapper = styled.div`
  display: flex;
  border: 2px solid #6f6f6f;
  align-items: center;
  border-radius: 5px;
`;

const Code = styled.p`
  color: #fff;
  // background: red;
  font-size: 1.8rem;
  // padding: 10px 20px;
  padding: 7px 10px;
  border-right: 1px solid #6f6f6f;
  width: auto;
`;

const CountryCode = ({
  placeholder,
  type,
  helperText = "",
  error = false,
  phoneCode,
  ...props
}) => {
  console.log(placeholder, props, error, helperText, "testing");
  return (
    <div style={{ position: "relative", marginBottom: "1.4rem" }}>
      <Wrapper>
        {/* <Code>{phoneCode}</Code> */}
        <Code>{phoneCode.includes("+") ? phoneCode : `+${phoneCode}`}</Code>
        <CountryWrapper
          placeholder={placeholder}
          {...props}
          $isHelperText={props?.helperText}
        />
      </Wrapper>
      {error && (
        <HelperText $istext={props?.helperText}>{helperText}</HelperText>
      )}
    </div>
  );
};

export default CountryCode;
