import React from "react";
import Styled from "styled-components";
import { BsChevronUp, BsChevronDown } from "react-icons/bs";

const PriorityWrapper = Styled.div`

display:flex;
align-items:center;
background:#091512;
border-radius:10px;
padding:1.2rem;
gap:9.8rem;
margin-bottom:1.2rem;

p{
  font-size:1.4rem;
  font-weight:700;
  margin-bottom:0.6rem;
  span{
    font-weight:400;
  }
}

.arrowwrapper{
  display: flex;
  flex-direction: column;
  gap:.8rem;
  svg{
    width:1.8rem;
    height:1.8rem;
    border:1px solid rgba(255, 255, 255, 0.4);
    cursor:pointer;
  }
}
`;

const PriorityPreference = () => {
  return (
    <PriorityWrapper>
      <div>
        <p>Token from collection XYZ (NFT)</p>
        <p>
          Remove : <span>1 Ton</span>
        </p>
        <p>
          Address : <span>0*123413434....</span>
        </p>
        <p>
          Price : <span>0.15ETH ($620)</span>
        </p>
      </div>
      <div className="arrowwrapper">
        <BsChevronUp />
        <BsChevronDown />
      </div>
    </PriorityWrapper>
  );
};

export default PriorityPreference;
