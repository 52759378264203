import React from "react";
import styled from "styled-components";

const DashboardStatsWrapper = styled.div`
  margin-bottom: 1.6rem;
  color: #fff;
  .stats {
    display: flex;
    // justify-content: center;
    align-items: center;
    gap: 0.8rem;
  }
  p {
    // font-size: 12.8rem;
    font-size: 9.8rem;
    line-height: 0.8;
  }
  h2 {
    color: #fff;
    font-size: 3rem;
    line-height: 1;
  }
  h6 {
    color: #fff;
    font-size: 2rem;
  }
`;

const DashboardStats = ({ heading, count, tons, co2 }) => {
  return (
    <DashboardStatsWrapper>
      <h6>{heading}</h6>
      <div className="stats">
        <p>{count}</p>
        <div>
          <h2>{tons}</h2>
          <h2>{co2}</h2>
        </div>
      </div>
    </DashboardStatsWrapper>
  );
};

export default DashboardStats;
