// import React, { useState } from "react";
// import Styled from "styled-components";
// import { BsFillQuestionCircleFill } from "react-icons/bs";

// const CheckboxWrapper = Styled.div`
// // background-color:red;
// margin-left:1rem;
// h2{
//   display: flex;
//   align-items: center;
//   gap:1rem;
//   margin-bottom: 0.2rem;
//   svg{
//     width:1.8rem;
//     height:1.8rem;
//   }
// }

// .form-group {
//   display: block;
//   margin-bottom: .8rem;
// }

// .form-group input {
//   padding: 0;
//   height: initial;
//   width: initial;
//   margin-bottom: 0;
//   display: none;
//   cursor: pointer;
// }

// .form-group label {
//   position: relative;
//   cursor: pointer;
//   font-size:1.4rem;
// }

// .form-group label:before {
//   content:'';
//   -webkit-appearance: none;
//   background-color: transparent;
//   border: 2px solid #fff;
//   box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
//   padding: 7px;
//   display: inline-block;
//   position: relative;
//   vertical-align: middle;
//   cursor: pointer;
//   margin-right: 5px;
// }

// .form-group input:checked + label:after {
//   // content: '';
//   // display: block;
//   // position: absolute;
//   // top: -2px;
//   // left: 9px;
//   // width: 6px;
//   // height: 14px;
//   // border: solid #fff;
//   // border-width: 0 2px 2px 0;
//   // transform: rotate(45deg);
//   content: '';
//   display: block;
//   position: absolute;
//   top: 3px;
//   left: 6px;
//   width: 6px;
//   height: 9px;
//   border: solid #fff;
//   border-width: 0 2px 2px 0;
//   transform: rotate(45deg);
// }
// `;

// const Checkbox = ({ heading, options }) => {
//   const [selectedOptions, setSelectedOptions] = useState([]);

//   const handleCheckboxChange = (option) => {
//     if (selectedOptions.includes(option)) {
//       setSelectedOptions(selectedOptions.filter((item) => item !== option));
//     } else {
//       setSelectedOptions([...selectedOptions, option]);
//     }
//   };

//   return (
//     <CheckboxWrapper>
//       <h2>
//         {heading} <BsFillQuestionCircleFill />
//       </h2>
//       {options?.map((option, index) => (
//         <div key={index} className="form-group">
//           <input
//             type="checkbox"
//             id={`checkbox-${index}`}
//             checked={selectedOptions.includes(option)}
//             onChange={() => handleCheckboxChange(option)}
//           />
//           <label htmlFor={`checkbox-${index}`}>{option}</label>
//         </div>
//       ))}
//     </CheckboxWrapper>
//   );
// };

// export default Checkbox;

import React, { useState } from "react";
import Styled from "styled-components";
import { BsFillQuestionCircleFill } from "react-icons/bs";

const CheckboxWrapper = Styled.div`
// background-color:red;
margin-left:1rem;
h2{
  display: flex;
  align-items: center;
  gap:1rem;
  margin-bottom: 0.2rem;
  svg{
    width:1.8rem;
    height:1.8rem;
  }
}

.form-group {
  display: block;
  margin-bottom: .8rem;
}

.form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.form-group label {
  position: relative;
  cursor: pointer;
  font-size:1.4rem;
}

.form-group label:before {
  content:'';
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 7px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
}

.form-group input:checked + label:after {
  // content: '';
  // display: block;
  // position: absolute;
  // top: -2px;
  // left: 9px;
  // width: 6px;
  // height: 14px;
  // border: solid #fff;
  // border-width: 0 2px 2px 0;
  // transform: rotate(45deg);
  content: '';
  display: block;
  position: absolute;
  top: 3px;
  left: 6px;
  width: 6px;
  height: 9px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
`;

const Checkbox = ({ heading, options, selectedOption, setSelectedOption }) => {
  // const Checkbox = ({ heading, options }) => {
  // const [selectedOption, setSelectedOption] = useState(null);

  const handleRadioChange = (option) => {
    setSelectedOption(option);
    console.log(option, "option");
  };
  return (
    <CheckboxWrapper>
      <h2>
        {heading} <BsFillQuestionCircleFill />
      </h2>
      {options?.map((option, index) => (
        <div key={option?.id} className="form-group">
          {/* {console.log(option?.name, "option?.name")} */}
          <input
            type="radio"
            id={`radio-${option?.name}`}
            checked={selectedOption === option?.id}
            onChange={() => handleRadioChange(option?.id)}
          />
          <label htmlFor={`radio-${option?.name}`}>{option?.name}</label>
        </div>
      ))}
    </CheckboxWrapper>
  );
};

export default Checkbox;
