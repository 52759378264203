import React, { useState, useEffect } from "react";
import styled from "styled-components";

const PercentageWrapper = styled.div`
  span {
    font-size: 2.4rem;
  }

  color: #fff;
  display: flex;
  gap: 0.8rem;

  .wrapper {
    display: flex;
    flex-direction: column;
    button {
      // all: unset;
      // width: 1.6rem;
      // color: #000;
      // background-color: #fff;
      // border: 1px solid #000;
      // text-align: center;
      // height: 1.6rem;
      // cursor: pointer;
      // font-size: 1.6rem;
      // display: flex;
      // justify-content: center;
      // align-items: center;

      all: unset;
      width: 1.6rem;
      color: #000;
      background-color: #fff;
      border: 1px solid #000;
      text-align: center;
      height: 1.4rem;
      cursor: pointer;
      font-size: 1.6rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 2px;
    }
  }
`;

// const PercentageWithButtons = ({ setPercentageV, testPercentage }) => {
const PercentageWithButtons = ({ percentage, setPercentage }) => {
  // console.log(setPercentageV, "setPercentageV");
  // const [percentage, setPercentage] = useState(0);

  const handleIncrease = () => {
    // setPercentage((prevPercentage) => prevPercentage + 1);
    if (percentage < 100) {
      setPercentage((prevPercentage) => prevPercentage + 1);
      // setPercentageV(() => percentage);
    }
  };

  const handleDecrease = () => {
    // setPercentage((prevPercentage) => prevPercentage - 1);
    if (percentage > 0) {
      setPercentage((prevPercentage) => prevPercentage - 1);
      // setPercentageV(percentage);
    }
  };

  // useEffect(() => {
  //   setPercentage(testPercentage);
  // }, [testPercentage]);

  return (
    <PercentageWrapper>
      <span>{percentage}%</span>
      <div className="wrapper">
        <button onClick={handleIncrease}> +</button>
        <button onClick={handleDecrease}>-</button>
      </div>
    </PercentageWrapper>
  );
};

export default PercentageWithButtons;
