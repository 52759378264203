import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Checkbox from "../../component/Checkbox/Checkbox";
import PriorityPreference from "../../component/PriorityPreference/PriorityPreference";
import { BsFillQuestionCircleFill } from "react-icons/bs";
import PageTitle from "../../component/PageTitle/PageTitle";
import testingdata from "../../services/settings";
import Button from "../../component/Button/Button";
import { toast } from "react-toastify";

const SettingsWrapper = styled.div`
  color: #fff;
  h1 {
    color: #fff;
    font-size: 4rem;
  }
  h2 {
    font-size: 2rem;
    color: #fff;
    line-height: 1.5;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  .priority {
    background-color: #000;
    padding: 0.8rem;
    padding: 1.9rem;
    border-radius: 9px;
  }
  .main-heading {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 0.2rem;
    svg {
      width: 1.8rem;
      height: 1.8rem;
    }
  }
`;

const blockchainOptions = ["Etherum", "Polygon", "Ripple"];
// const checkboxOptions2 = ["ERC 721", "ERC 723", "ERC 724"];
const checkboxOptions3 = [
  "Energy Generation",
  "Transportation",
  "Heavy Industry",
  "Agriculture and Land Use",
  " Waste Management",
];
const checkboxOptions4 = ["Toucan", "MintCarbon", "Flowcarbon", "KlimaDAO"];
const checkboxOptions5 = [
  "Afforestation and Reforestation",
  " Soil Carbon Sequestration",
  "Carbon Capture and Storage (CCS)",
  "Bioenergy with Carbon Capture and Storage (BECCS)",
  "Ocean-based Carbon Capture and Storage",
  "Direct Air Capture (DAC)",
  "Enhanced Weathering",
];

const Settings = () => {
  const [retirementTypeOption, setRetirementTypeOption] = useState();
  const [tokenType, setTokenType] = useState();
  const [getAllIndustry, setGetAllIndustry] = useState();
  const [getAllMarket, setGetAllMarket] = useState();
  const [getAllRetirement, setGetAllRetirement] = useState();
  const [blockchainCurrency, setBlockchainCurrency] = useState();

  const [blockChainOption, setBlockchainOption] = useState();
  const [tokenOption, setTokenOption] = useState();
  const [industryOption, setindustryOption] = useState();
  const [marketOption, setMarketOPtion] = useState();
  const [retirementOption, setRetirementOPtion] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [userConfigurationData, setUserConfiguration] = useState();

  // const checkboxOptions5 = retirementTypeOption?.
  const getAllRetirementType = async () => {
    await fetch(
      `https://dev4.instantsolutionslab.site/api/get-all-retirement-types`,
      {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
      .then((resp) => resp.json())
      .then((json) => {
        // setData(json?.data?.data);
        // setRetirementTypeOption(json?.data?.data);
        console.log(json?.data);
        setGetAllRetirement(json?.data);
      });
  };

  const getAllTradingPairs = async () => {
    await fetch(
      `https://dev4.instantsolutionslab.site/api/get-all-trading-pairs`,
      {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
      .then((resp) => resp.json())
      .then((json) => {
        // setData(json?.data?.data);
        // setRetirementTypeOption(json?.data?.data);
        console.log(json?.data);
        setBlockchainCurrency(json?.data);
      });
  };
  const getAllMarketPlaces = async () => {
    await fetch(
      `https://dev4.instantsolutionslab.site/api/get-all-market-places`,
      {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
      .then((resp) => resp.json())
      .then((json) => {
        // setData(json?.data?.data);
        // setRetirementTypeOption(json?.data?.data);
        console.log(json?.data);
        setGetAllMarket(json?.data);
      });
  };
  const getAllIndustries = async () => {
    await fetch(
      `https://dev4.instantsolutionslab.site/api/get-all-industries`,
      {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
      .then((resp) => resp.json())
      .then((json) => {
        // setData(json?.data?.data);
        // setRetirementTypeOption(json?.data?.data);
        console.log(json?.data);
        setGetAllIndustry(json?.data);
      });
  };

  const getAllTokens = async () => {
    await fetch(
      `https://dev4.instantsolutionslab.site/api/get-all-token-types`,
      {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
      .then((resp) => resp.json())
      .then((json) => {
        // setData(json?.data?.data);
        // setRetirementTypeOption(json?.data?.data);
        setTokenType(json?.data);
        // console.log(json?.data, "token types");
      });
  };
  const getUserConfiguration = async () => {
    await fetch(
      `https://dev4.instantsolutionslab.site/api/get-user-configuration`,
      {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
      .then((resp) => resp.json())
      .then((json) => {
        // setData(json?.data?.data);
        // setRetirementTypeOption(json?.data?.data);
        // setTokenType(json?.data);
        console.log(json?.data, "token types");
        setUserConfiguration(json?.data);
      });
  };

  const createUserConfiguration = async () => {
    setIsLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("token")}`
    );

    var formdata = new FormData();
    formdata.append("trading_pair_id", blockChainOption);
    formdata.append("market_place_id", marketOption);
    formdata.append("retirement_type_id", retirementOption);
    formdata.append("industry_id", industryOption);
    formdata.append("token_type_id", tokenOption);
    // retirementOption;

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      "https://dev4.instantsolutionslab.site/api/create-user-configuration",
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        // toast.success(result?.message);
        toast.success("Configuration Updated Successfully.");
        console.log(result);
      })
      .catch((error) => console.log("error", error))
      .finally(() => {
        setIsLoading(false);
      });
  };

  // console.log(retirementTypeOption?.name, "dataqowriuq");
  // console.log(blockChainOption, "blockChainOption");

  useEffect(() => {
    setTimeout(() => {
      getAllRetirementType();
      getAllTradingPairs();
      getAllMarketPlaces();
      getAllIndustries();
      getAllTokens();
    }, [500]);
    getUserConfiguration();
  }, []);

  // console.log(tokenType, "tokenType");
  // console.log(getAllIndustry, "getAllIndustry");
  // console.log(getAllMarket, "getAllMarket");
  // console.log(getAllRetirement, "getAllRetirement");
  // console.log(blockchainCurrency, "blockchainCurrency");
  console.log(
    blockChainOption,
    tokenOption,
    industryOption,
    marketOption,
    retirementOption,
    "options"
  );

  const handleUpdateConfiguration = () => {
    // console.log("it clicked");
    if (
      blockChainOption &&
      tokenOption &&
      industryOption &&
      marketOption &&
      retirementOption
    ) {
      console.log("it work");

      createUserConfiguration();
    } else {
      console.log("it doesn't work");
      toast.error("Select all the configuration");
    }
  };

  useEffect(() => {
    // setTimeout(() => {
    // console.log(userConfigurationData?.trading_pair_id, "testing data");
    console.log(" it works");
    setBlockchainOption(Number(userConfigurationData?.trading_pair_id) || "1");
    setTokenOption(Number(userConfigurationData?.token_type_id) || "1");
    setindustryOption(Number(userConfigurationData?.industry_id) || "1");
    setMarketOPtion(Number(userConfigurationData?.market_place_id) || "1");
    setRetirementOPtion(
      Number(userConfigurationData?.retirement_type_id) || "1"
    );
    // }, 1000);
  }, [userConfigurationData]);

  return (
    <>
      <SettingsWrapper>
        <PageTitle title="Pay4Climate - setting" />
        <h1>Settings</h1>
        <Wrapper>
          <div>
            <h2>Configure</h2>
            <Checkbox
              heading="Blockchain"
              // options={blockchainCurrency?.map((item) => item.name)}
              options={blockchainCurrency?.map((item) => item)}
              selectedOption={blockChainOption}
              setSelectedOption={setBlockchainOption}
            />
            {/* <Checkbox heading="Token types" options={checkboxOptions2} /> */}
            <Checkbox
              heading="Token types"
              // options={tokenType?.map((item) => item.name)}
              options={tokenType?.map((item) => item)}
              selectedOption={tokenOption}
              setSelectedOption={setTokenOption}
            />
            <Checkbox
              heading="Industries"
              // options={getAllIndustry?.map((item) => item.name)}
              options={getAllIndustry?.map((item) => item)}
              selectedOption={industryOption}
              setSelectedOption={setindustryOption}
            />
            <Checkbox
              heading="Marketplaces"
              // options={getAllMarket?.map((item) => item.name)}
              options={getAllMarket?.map((item) => item)}
              selectedOption={marketOption}
              setSelectedOption={setMarketOPtion}
            />
            <Checkbox
              heading="Negative emissions solutions"
              // options={getAllRetirement?.map((item) => item.name)}
              options={getAllRetirement?.map((item) => item)}
              selectedOption={retirementOption}
              setSelectedOption={setRetirementOPtion}
            />
            <Button
              style={{ marginLeft: "1rem", width: "50%" }}
              loading={isLoading}
              onClick={handleUpdateConfiguration}
            >
              Update Configuration
            </Button>
          </div>

          <div className="priority">
            <h2 className="main-heading">
              PriorityPreference <BsFillQuestionCircleFill />
            </h2>
            <PriorityPreference />
            <PriorityPreference />
            <PriorityPreference />
            <PriorityPreference />
            <PriorityPreference />
          </div>
        </Wrapper>
      </SettingsWrapper>
    </>
  );
};

export default Settings;
