import React, { useState } from "react";
import styled from "styled-components";
import UserSideBar from "./UserSideBar/UserSideBar";
import Detail from "../../component/Admin/Detail/Detail";
import Modal from "../../component/Modal/Modal";

import Dashboard from "./Dashboard";
import History from "./History";
import Profile from "./Profile";
import Settings from "./Settings";
import Support from "./Support";

import Header from "../../component/Header/Header";

const AdminDashboardParentWrapper = styled.div`
  // background: red;
`;

const AdminDashboardWrapper = styled.div`
  display: grid;
  grid-template-columns: 20fr 80fr;
  height: 100vh;
`;

const SidebarWrapper = styled.div`
  // background: red;
  border-right: 2px solid #6f6f6f;
`;
const DetailsWrapper = styled.div`
  // background: yellow;
  padding: 1.6rem 3.2rem;
  overflow-y: scroll;
`;

const sidebarItems = [
  { label: "Dashboard" },
  { label: "Reports" },
  { label: "Settings" },
  { isSectionTitle: true, label: "Secondary" },
  { label: "Profile" },
  { label: "Messages" },
  { label: "Notifications" },
  // Add more items as needed
];

const UserDasbboard = () => {
  const [activeTab, setActiveTab] = useState(0);
  return (
    <>
      <AdminDashboardParentWrapper>
        {/* <Header /> */}
        <AdminDashboardWrapper>
          <SidebarWrapper>
            <UserSideBar
              item={sidebarItems}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          </SidebarWrapper>
          <DetailsWrapper>
            {/* <Detail /> */}

            {activeTab === 0 && <Dashboard />}
            {activeTab === 1 && <Settings />}
            {activeTab === 2 && <History />}
            {activeTab === 3 && <Profile />}
            {activeTab === 4 && <Support />}
          </DetailsWrapper>
        </AdminDashboardWrapper>
      </AdminDashboardParentWrapper>
    </>
  );
};

export default UserDasbboard;
