import React from "react";
import { useTable } from "react-table";
import styled from "styled-components";
import Pagination from "react-js-pagination";

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  color: #fff;
  th,
  td {
    // border: 1px solid #ccc;
    border: 1px solid #6f6f6f;
    padding: 8px;
    text-align: left;
  }
  th {
    // background-color: #f2f2f2;
    font-size: 2rem;
  }
  tbody tr:nth-child(even) {
    // background-color: #f9f9f9;
    // background-color: #e5e5e5;
  }
  tbody tr {
    // background-color: #f9f9f9;
    // background-color: #e5e5e5;
    font-size: 1.8rem;
  }
`;

const TableComponent = ({
  columns,
  data,
  activePage,
  totalItemsCount,
  itemsCountPerPage,
  onChange,
  // handleLastItemClick2 = false,
  isDisplayPagnation = true,
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  // const handleLastItemClick = (row) => {
  //   const selectedValues = row.cells.map((cell) => cell.value);
  //   console.log("Selected values:", selectedValues);
  // };

  return (
    <>
      <Table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                // onClick={() => {
                //   if (handleLastItemClick2 && row.index === rows.length - 1) {
                //     // handleLastItemClick(row);
                //     handleLastItemClick(row);
                //     console.log(row, "row");
                //   }
                // }}
              >
                {row.cells.map((cell) => (
                  // <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  <td
                    {...cell.getCellProps()}
                    // onClick={
                    //   rowIndex === rows.length - 1 &&
                    //   cellIndex === row.cells.length - 1
                    //     ? handleLastItemClick
                    //     : null
                    // }
                    // onClick={(e) => {
                    //   e.stopPropagation(); // Prevent click on cell from triggering row click
                    // }}
                  >
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </Table>
      {isDisplayPagnation && (
        <>
          <Pagination
            activePage={activePage}
            itemsCountPerPage={itemsCountPerPage}
            totalItemsCount={totalItemsCount}
            // pageRangeDisplayed={2}
            onChange={onChange}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First"
            lastPageText="Last"
          />
        </>
      )}
    </>
  );
};

export default TableComponent;
