// import Button from "./component/Button";
// import Button from "./component/Button/Button";
import Layout from "./component/Layout/Layout";
import Modal from "./component/Modal/Modal";

function App() {
  return (
    <div className="App">
      {/* <h1>UI</h1>
      <Button variant="contained">Contained</Button> */}
      <Layout />
    </div>
  );
}

export default App;
