// import PropTypes from "prop-types";
// import TextField from "@mui/material/TextField";
// import styled from "@emotion/styled";
// import { Typography } from "@mui/material";
// // import { colors } from "@config/colors";
// import { colors } from "../../config/colors";

// const StyledInput = styled("div")({
//   display: "flex",
//   flexDirection: "column",
//   alignItems: "flex-start",
//   width: "100%",
// });
// function InputField({
//   variant,
//   ref,
//   children,
//   InputProps,
//   styledInputProps,
//   ...props
// }) {
//   const inputVariant = () => (
//     <StyledInput {...styledInputProps}>
//       {children ? (
//         <Typography variant="s3" color={colors.greyText[900]} sx={{ mb: 1 }}>
//           {children}
//         </Typography>
//       ) : null}
//       <TextField
//         variant={variant}
//         autoComplete
//         {...props}
//         InputProps={InputProps}
//       />
//     </StyledInput>
//   );
//   return inputVariant();
// }

// InputField.propTypes = {
//   variant: PropTypes.string,
//   children: PropTypes.string,
//   error: PropTypes.bool,
// };

// InputField.defaultProps = {
//   variant: "outlined",
//   autoComplete: "off",
//   error: false,
// };
// export default InputField;

// import React, { useState } from "react";

// import styled from "styled-components";

// const InputWrapper = styled.input`
//   // background-color: green;
//   padding: 10px 20px;
//   // background-color: red;
//   color: #fff;
//   border: none;
//   border-radius: 5px;
//   // cursor: pointer;
//   width: 100%;
//   background: #060e0c;
//   // border: 1.5px solid #fff;
//   border: 2px solid #6f6f6f;
//   &:focus-visible {
//     outline: none;
//   }
// `;

// const InputField = ({ placeholder, ...props }) => {
//   const [showPassword, setShowPassword] = useState(false);

//   const handleTogglePassword = () => {
//     setShowPassword((prevState) => !prevState);
//   };
//   console.log(props.type, "props");
//   <InputWrapper
//     type={showPassword ? "text" : "password"}
//     placeholder={placeholder}
//     {...props}
//   />;
//   {
//     props?.type === "password" && (
//       <button onClick={handleTogglePassword}>
//         {showPassword ? "Hide" : "Show"} Password
//       </button>
//     );
//   }
// };

// export default InputField;

// return <InputWrapper placeholder={placeholder} {...props} />;
// return <InputWrapper />InputField</InputWrapper>;

import React, { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

const HelperText = styled.p`
  color: red;
  // color: ${(props) => props.$istext && "red"};
  font-size: 1.2rem;
  position: absolute;
  bottom: -2px;
  left: 2px;
`;

const InputWrapper = styled.input`
  padding: 10px 20px;
  color: #fff;
  border: none;
  border-radius: 5px;
  width: 100%;
  background: #060e0c;
  // background: ${(props) => props.$isHelperText && "yellow"};
  border: 2px solid #6f6f6f;

  &:focus-visible {
    outline: none;
  }
`;

const PasswordWrapper = styled.div`
  height: 41px;
  padding: 10px 20px;
  color: #fff;
  border: none;
  border-radius: 5px;
  width: 100%;
  background: #060e0c;
  border: 2px solid #6f6f6f;

  display: flex;
  align-items: center;
  width: 100%;

  .input-wrapper {
    border: none;
    width: 100%;
    background: #060e0c;

    color: #fff;
    border: none;
    border-radius: 5px;
    width: 100%;
    background: #060e0c;

    &:focus-visible {
      outline: none;
    }
  }
`;

const InputField = ({
  placeholder,
  type,
  helperText = "",
  error = false,
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false);

  {
    /* <button onClick={handleTogglePassword}>
            {showPassword ? "Hide" : "Show"} Password
          </button> */
  }

  const handleTogglePassword = () => {
    setShowPassword((prevState) => !prevState);
  };
  // console.log({ props }, "props");
  // console.log(type, "props");
  return (
    <>
      {type === "password" ? (
        <div style={{ position: "relative" }}>
          <PasswordWrapper>
            <input
              type={showPassword ? "text" : type}
              placeholder={placeholder}
              className="input-wrapper"
              {...props}
            />
            {showPassword ? (
              <>
                <AiFillEyeInvisible
                  style={{
                    color: "#fff",
                    width: "2.4rem",
                    height: "2.4rem",
                    cursor: "pointer",
                  }}
                  onClick={handleTogglePassword}
                />
              </>
            ) : (
              <>
                {" "}
                <AiFillEye
                  style={{
                    color: "#fff",
                    width: "2.4rem",
                    height: "2.4rem",
                    cursor: "pointer",
                  }}
                  onClick={handleTogglePassword}
                />
              </>
            )}
          </PasswordWrapper>
          {error && (
            <HelperText style={{ bottom: "-16px" }}>{helperText}</HelperText>
          )}
        </div>
      ) : (
        <>
          <div style={{ position: "relative" }}>
            <InputWrapper
              placeholder={placeholder}
              {...props}
              $isHelperText={props?.helperText}
            />
            {error && (
              <HelperText $istext={props?.helperText}>{helperText}</HelperText>
            )}
          </div>
        </>
      )}
    </>
  );
};

InputField.propTypes = {
  type: PropTypes.string,
};

// Specifies the default values for props:
InputField.defaultProps = {
  type: "text",
};

export default InputField;
