import { request } from "../config/verb";
import { setDataInLocalStorage } from "./general";

// Login
export const signin = async (values) => {
  console.log(values, "values from auth");
  try {
    const token = await request("login", values, "post");
    // console.log(token?.data?.user?.role, "auth");
    // console.log(token?.data?.user?.role, "auth");
    setDataInLocalStorage("token", token?.data?.token);
    setDataInLocalStorage("role", token?.data?.user?.role);
    // setDataInLocalStorage("refreshToken", token?.data?.data?.refreshToken);
    // setDataInLocalStorage("tokenExpiry", JSON.stringify(new Date().getTime()));
    return token;
  } catch (err) {
    console.log(err);
  }
};

// signup
export const signup = async (values) => {
  console.log(values, "values from auth");
  try {
    const res = await request("register", values, "post");
    console.log(res?.data, "auth");
    // setDataInLocalStorage("token", token?.data?.token);
    // setDataInLocalStorage("refreshToken", token?.data?.data?.refreshToken);
    // setDataInLocalStorage("tokenExpiry", JSON.stringify(new Date().getTime()));
    return res;
  } catch (err) {
    console.log(err);
    return err;
  }
};

// forgot password
export const forgotPassword = async (values) => {
  console.log(values, "values from auth");
  try {
    const token = await request("forget/password", values, "post");
    console.log(token?.data, "auth");
    // setDataInLocalStorage("token", token?.data?.token);
    // setDataInLocalStorage("refreshToken", token?.data?.data?.refreshToken);
    // setDataInLocalStorage("tokenExpiry", JSON.stringify(new Date().getTime()));
    return token;
  } catch (err) {
    console.log(err);
    return err;
  }
};

// code verification
export const codeVerfication = async (values) => {
  console.log(values, "values from auth");
  try {
    const token = await request("verify/code", values, "post");
    console.log(token?.data, "auth");
    // setDataInLocalStorage("token", token?.data?.token);
    // setDataInLocalStorage("refreshToken", token?.data?.data?.refreshToken);
    // setDataInLocalStorage("tokenExpiry", JSON.stringify(new Date().getTime()));
    return token;
  } catch (err) {
    console.log(err);
    return err;
  }
};

// set password
export const setpassword = async (values) => {
  console.log(values, "values from auth");
  try {
    const token = await request("set/password", values, "post");
    console.log(token?.data, "auth");
    // setDataInLocalStorage("token", token?.data?.token);
    // setDataInLocalStorage("refreshToken", token?.data?.data?.refreshToken);
    // setDataInLocalStorage("tokenExpiry", JSON.stringify(new Date().getTime()));
    return token;
  } catch (err) {
    console.log(err);
    return err;
  }
};
