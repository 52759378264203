/* eslint-disable no-unused-expressions */
// import {
//   getDataFromLocalStorage,
//   setDataInLocalStorage,
// } from "@services/general";
import {
  getDataFromLocalStorage,
  setDataInLocalStorage,
} from "../services/general";
import axios from "axios";
// import { store } from "@redux/store";
// import apiConfig from "./api.service";

// const api = apiConfig.url;

function getHashCode() {
  // const state = store.getState();
  // return state.user?.hashData?.hashCode || "bypass";
  return "bypass";
}

const api = "https://dev4.instantsolutionslab.site/api/";
// const api = "http://fuma-core-api.us-east-1.elasticbeanstalk.com/v1/api/";
// const api = "http://192.168.250.119:3000/v1/api/";
// const api = "http://192.168.250.119:3000/v1/api/";

// Function that will be called to refresh authorization
// const refreshAuthLogic = (failedRequest) => {
//   console.log("failedRequest", failedRequest);
//   axios
//     .post(`${api}auth/refresh`, {
//       refreshToken: JSON.parse(localStorage.getItem("refreshToken")),
//     })
//     .then((tokenRefreshResponse) => {
//       localStorage.setItem(
//         "idToken",
//         JSON.stringify(tokenRefreshResponse.data.data.IdToken)
//       );
//       // added token expiry, when refreshed
//       localStorage.setItem("tokenExpiry", JSON.stringify(new Date().getTime()));
//       failedRequest.headers.Authorization =
//         tokenRefreshResponse.data.data.IdToken;

//       return Promise.resolve();
//     });
// };
// Add a request interceptor
// axios.interceptors.request.use(
//   (config) => {
//     const tokenExpiry =getDataFromLocalStorage("tokenExpiry");
//     const currentTime = new Date().getTime();
//     if (tokenExpiry) {
//       const tokenExpired = (tokenExpiry - currentTime) / 60000 <= 5;
//       if (tokenExpired) {
//       return  refreshAuthLogic(config);
//       }
//     }
//     return config;
//   },
//   (error) => Promise.reject(error)
// );

// Add a response interceptor
// axios.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     const originalRequest = error.config;
//     if (error?.response?.status === 401 && !originalRequest._retry) {
//       originalRequest._retry = true;
//       return refreshAuthLogic(error.response.config);
//     }
//     return Promise.reject(error);
//   }
// );

export const fetchToken = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");
  if (token) {
    setDataInLocalStorage("token", token);
    return token;
  }
  return getDataFromLocalStorage("token");
};

export const request = (
  url,
  data,
  type,
  noHeaders = false,
  params = null,
  organization = false
) =>
  new Promise((resolve, reject) => {
    // console.log(user, "coming form verb");
    console.log(url, data, type, noHeaders, "noHeaders");
    let token = "";
    const apiUrl = api;
    const requestObj = {
      method: type,
      url: apiUrl + url,
      headers: {
        // "x-aws-verification-token": "coahj19x1ja84bvdsn28033xaj2",
        // "x-signature-token": "bypass",
        // "x-signature-token": getHashCode(),
      },
    };
    noHeaders || (token = fetchToken());
    type !== "get" && type !== "delete" && (requestObj.data = data);
    noHeaders || (requestObj.headers.authorization = `Bearer ${token}`);
    organization && (requestObj.headers["x-organization-id"] = organization);
    params && (requestObj.params = params);
    axios(requestObj).then(resolve, reject);
  });
