import { configureStore } from "@reduxjs/toolkit";
// import { generalReducer, userReducer } from "@redux/index";
import { generalReducer } from "./index";

export const store = configureStore({
  reducer: {
    // user: userReducer,
    general: generalReducer,
  },
});
