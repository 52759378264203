import React, { useState, useRef, useEffect } from "react";
import { ChromePicker } from "react-color";
import ClickAwayListener from "@mui/material/ClickAwayListener";

const ColorPickerComponent = ({ color, setColor }) => {
  // const [color, setColor] = useState("#FFFFFF");
  const [showPicker, setShowPicker] = useState(false);
  const pickerRef = useRef();

  // const handleColorChange = (newColor) => {
  //   setColor(newColor.hex);
  // };

  const handleClickOutside = (event) => {
    if (pickerRef.current && !pickerRef.current.contains(event.target)) {
      setShowPicker(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  // const [color, setColor] = useState("#FFFFFF"); // Initial color

  const handleColorChange = (newColor) => {
    setColor(newColor.hex);
  };
  const handleClickAway = () => {
    console.log("it click away");
  };
  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div ref={pickerRef}>
        <div style={{ position: "relative" }}>
          {showPicker && (
            <ChromePicker
              color={color}
              onChange={handleColorChange}
              style={{
                position: "absolute",
              }}
            />
          )}
          <div>
            <p
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "93px",
                height: "30px",
                backgroundColor: "#fff",
                cursor: "pointer",
                color: "#000",
                borderRadius: "5px",
              }}
              onClick={() => setShowPicker(!showPicker)}
            >
              {color}
            </p>
          </div>
        </div>
      </div>
    </ClickAwayListener>
    // <div>
    //   <h2>Color Picker</h2>
    //   <div ref={pickerRef}>
    //     <div onClick={() => setShowPicker(!showPicker)}>
    //       <div
    //         style={{
    //           width: "36px",
    //           height: "14px",
    //           borderRadius: "2px",
    //           background: color,
    //         }}
    //       />
    //     </div>
    //     {showPicker && (
    //       <div style={{ position: "absolute", zIndex: "2" }}>
    //         <ChromePicker color={color} onChange={handleColorChange} />
    //       </div>
    //     )}
    //   </div>
    //   <p>Selected Color: {color}</p>
    // </div>
  );
};

export default ColorPickerComponent;
