import React, { useState, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "../pages/Home";
import NotFound from "../component/NotFund/NotFound";
import Signin from "../component/Signin/Signin";
import ForgetPassword from "../component/ForgetPassword/ForgetPassword";
import Verfication from "../component/Verify/Verification";
// import verification from "../component/verify/verification";
import ResetPassword from "../component/ResetPassword/ResetPassword";
import Admin from "../component/Admin/Admin";
import UserDasbboard from "../pages/user/UserDasbboard";
// import Widgetview from "../pages/Widgetview";
// import Widgetview from "../pages/Widgetview";
import WidgetPreview from "../WidgetPreview/WidgetPreview";
import AdminRoutes from "../AdminRoutes";
import UserRoutes from "./UserRoutes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import WidgetPreview from "../WidgetPreview/WidgetPreview";
import Widgetview from "../pages/Widgetview";
// import "react-notifications/lib/notifications.css";

const Routers = () => {
  const [show, setShow] = useState(false);

  let pathname = window.location.pathname;
  console.log(pathname);
  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");
  useEffect(() => {
    if (role == "admin") {
      // if (true) {
      setShow(true);
    } else {
      // navigate("/login");
      setShow(false);
    }
  }, []);

  if (
    (pathname === "/signin" ||
      pathname === "/forgetpassword" ||
      pathname === "/verification" ||
      pathname === "/reset-password" ||
      pathname === "/widgetview" ||
      pathname === "/widgetpreview" ||
      pathname === "/") &&
    !token
  ) {
    return (
      <>
        <div>
          <Routes>
            <Route exact path="/" element={<Navigate to="/signin" />} />
            <Route exact path="/signin" element={<Signin />} />
            <Route exact path="/forgetpassword" element={<ForgetPassword />} />
            <Route exact path="/verification" element={<Verfication />} />
            <Route exact path="/reset-password" element={<ResetPassword />} />
            <Route exact path="/widgetview" element={<Widgetview />} />
            <Route exact path="/widgetPreview" element={<WidgetPreview />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
          <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
          />
        </div>
      </>
    );
  } else {
    if (token != null) {
      // if (Role == "Admin") {

      console.log(show, "show");
      if (show) {
        return <AdminRoutes />;
      } else {
        return <UserRoutes />;
      }
    } else {
      return (
        <div>
          <Routes>
            <Route path="*" element={<Navigate to="/signin" />} />
          </Routes>
        </div>
      );
    }

    // return (
    //   <div>
    //     <Routes>
    //       <Route path="*" element={<NotFound />} />
    //     </Routes>
    //   </div>
    // );
  }
};

export default Routers;
