import React from "react";
import SidebarLi from "../SidebarLi/SidebarLi";
import styled from "styled-components";
import { HiUsers } from "react-icons/hi";
import { GrProductHunt } from "react-icons/gr";
import { RiLogoutCircleRFill } from "react-icons/ri";
import { AiFillSetting, AiOutlineHistory } from "react-icons/ai";

const SidebarLogoutWrapper = styled.div`
  // background: green;

  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.6rem 3.2rem;
`;
const UnorderList = styled.ul`
  // background: blue;
`;

const SignOutWrapper = styled.p`
  // background: blue;/
  font-size: 2rem;
  line-height: 1.5;
  color: #fff;
`;

const Sidebar = ({ activeTab, setActiveTab }) => {
  const handleSignOut = () => {
    setTimeout(() => {
      localStorage.removeItem("token");
      window.location.href =
        "https://pay4climate.instantsolutionslab.site/signin";
    }, 500);
  };
  return (
    <SidebarLogoutWrapper className="sidebar">
      <UnorderList>
        {/* <SidebarLi text="Home" icon={<HiUsers />} /> */}
        <SidebarLi
          text="Customers"
          handleActiveTab={() => setActiveTab(0)}
          activeStyle={activeTab === 0}
          icon={<HiUsers />}
        />
        {/* <SidebarLi text="Profile" icon={<GrProductHunt />} /> */}
        <SidebarLi
          text="CDR providers"
          handleActiveTab={() => setActiveTab(1)}
          activeStyle={activeTab === 1}
          icon={<GrProductHunt />}
        />
        <SidebarLi
          text="History"
          handleActiveTab={() => setActiveTab(2)}
          activeStyle={activeTab === 2}
          icon={<AiOutlineHistory />}
        />
        <SidebarLi
          text="Settings"
          handleActiveTab={() => setActiveTab(3)}
          activeStyle={activeTab === 3}
          icon={<AiFillSetting />}
        />
      </UnorderList>
      <SignOutWrapper>
        <SidebarLi
          text="Signout"
          onClick={handleSignOut}
          icon={<RiLogoutCircleRFill />}
        />
      </SignOutWrapper>
    </SidebarLogoutWrapper>
  );
};

export default Sidebar;
