import React from "react";
import styled from "@emotion/styled";
// import InputField from "../component/InputField/InputField";
import Button from "../component/Button/Button";
import InputField from "../component/InputField/InputField";

const StyledInput = styled("div")({
  display: "flex",
  // flexDirection: "column",
  // alignItems: "flex-start",
  // width: "100%",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
  width: "100vw",
  // backgroundColor: "yellow",
});

const Home = () => {
  return (
    <StyledInput>
      <div class="container demo">
        <div class="content">
          <div id="large-header" class="large-header">
            <canvas id="demo-canvas"></canvas>
            <div className="centercenter">
              <h1 class="main-title">
                <span class="thin">Pay4</span> Climate
              </h1>
              <div class="container" style={{ height: "140vh" }}>
                <div class="construction"></div>
                <p class="attribution" style={{ opacity: 0 }}>
                  The "
                  <a target="_blank" href="https://codepen.io/joshnh/pen/ohbHl">
                    Pure CSS Under Construction GIF
                  </a>
                  " was made by{" "}
                  <a target="_blank" href="https://codepen.io/joshnh/">
                    @joshnh
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledInput>
  );
};

export default Home;
