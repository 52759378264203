export const colors = {
  primary: {
    0: "#16558F",
    10: "#0583D2",
    20: "#00B3EB",
    40: "#329685",
    60: "#61B0B7",
    80: "#B8E3FF",
    100: "#00C495",
  },
  greyText: {
    0: "#FDFDFD",
    10: "#F1F1F1",
    20: "#EDEDED",
    40: "#E9E9E9",
    60: "#E4E4E4",
    80: "#DDDDDD",
    100: "#D5D5D5",
    200: "#CACACA",
    300: "#BDBDBD",
    400: "#ACACAC",
    500: "#979797",
    600: "#7D7D7D",
    700: "#5C5C5C",
    800: "#333333",
    900: "#1A1D1F",
  },
  error: {
    0: "#FEF0F3",
    100: "#FCDFE6",
    200: "#FAC0CE",
    300: "#F7A0B5",
    400: "#F5819D",
    500: "#F26184",
  },
  info: {
    0: "#F1F5FB",
    100: "#E4EDFA",
    200: "#C9DBF6",
    300: "#95B9ED",
    400: "#5992E2",
    500: "#3077DB",
  },
  success: {
    0: "#EFFCF7",
    100: "#DEF8EF",
    200: "#BEF1DF",
    300: "#9DEBCF",
    400: "#7DE4BF",
    500: "#5CDDAF",
    600: "#49B08B",
  },
  background: {
    0: "#FCFCFC",
    1: "#ECEAF3",
  },
};

// export const colors = {
//   primary: {
//     0: "#FBF9FD",
//     10: "#F0E8F9",
//     20: "#E0D1F3",
//     40: "#D1BAED",
//     60: "#C1A3E7",
//     80: "#B28CE1",
//     100: "#A274DB",
//     200: "#935DD5",
//     300: "#8346CF",
//     400: "#742FC9",
//     500: "#6418C3",
//     600: "#5A16B0",
//     700: "#50139C",
//     800: "#461189",
//     900: "#3C0E75",
//   },
//   greyText: {
//     0: "#FDFDFD",
//     10: "#F1F1F1",
//     20: "#EDEDED",
//     40: "#E9E9E9",
//     60: "#E4E4E4",
//     80: "#DDDDDD",
//     100: "#D5D5D5",
//     200: "#CACACA",
//     300: "#BDBDBD",
//     400: "#ACACAC",
//     500: "#979797",
//     600: "#7D7D7D",
//     700: "#5C5C5C",
//     800: "#333333",
//     900: "#1A1D1F",
//   },
//   error: {
//     0: "#FEF0F3",
//     100: "#FCDFE6",
//     200: "#FAC0CE",
//     300: "#F7A0B5",
//     400: "#F5819D",
//     500: "#F26184",
//   },
//   info: {
//     0: "#F1F5FB",
//     100: "#E4EDFA",
//     200: "#C9DBF6",
//     300: "#95B9ED",
//     400: "#5992E2",
//     500: "#3077DB",
//   },
//   success: {
//     0: "#EFFCF7",
//     100: "#DEF8EF",
//     200: "#BEF1DF",
//     300: "#9DEBCF",
//     400: "#7DE4BF",
//     500: "#5CDDAF",
//     600: "#49B08B",
//   },
//   background: {
//     0: "#FCFCFC",
//     1: "#ECEAF3",
//   },
//   purple: {
//     0: "#F8F5FB",
//     200: "#935DD5",
//   },
//   extras: {
//     0: "#FFF3EE",
//     10: "#F7F7F7",
//     20: "#C48B37",
//   },
//   requestMethodTags: {
//     GET: {
//       text: "#3077DB",
//       background: "#E9F2FF",
//     },
//     PUT: {
//       text: "#EFB800",
//       background: "#FFF8E3",
//     },
//     POST: {
//       text: "#49B08B",
//       background: "#E3FFE4",
//     },
//     DELETE: {
//       text: "#3077DB",
//       background: "#E9F2FF",
//     },
//     default: {
//       text: "pink",
//       background: "orange",
//     },
//   },
// };
