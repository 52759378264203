// import React from "react";

// const NotFound = () => {
//   return <div>NotFound</div>;
// };

// export default NotFound;

import React from "react";
import styled from "styled-components";

const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #1a1a1a;
`;

const ErrorMessage = styled.h1`
  font-size: 36px;
  color: #fff;
  margin-bottom: 16px;
`;

const NotFound = () => {
  return (
    <Container>
      <ErrorMessage>404 - Not Found</ErrorMessage>
      <p style={{ color: "#fff" }}>
        Sorry, the page you are looking for does not exist.
      </p>
    </Container>
  );
};

export default NotFound;
