import React, { useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";

function DynamicCopyToClipboard({ textToCopy, copy, setCopied }) {
  // const [copied, setCopied] = useState(false);
  // const textToCopy = "Hello, world!";

  const handleCopy = () => {
    setCopied(true);

    // Reset the "copied" state after a certain duration
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <div>
      <input type="text" value={textToCopy} readOnly />
      {/* <CopyToClipboard text={textToCopy}> */}
      <CopyToClipboard text={textToCopy}>
        <button onClick={handleCopy}>
          {copy ? "Copied!" : "Copy to Clipboard"}
        </button>
      </CopyToClipboard>
    </div>
  );
}

export default DynamicCopyToClipboard;
