// import React, { useEffect } from "react";

// const Modal = ({ isOpen, onClose, children }) => {
//   if (!isOpen) return null;

//   const modalOverlayStyle = {
//     position: "fixed",
//     top: 0,
//     left: 0,
//     right: 0,
//     bottom: 0,
//     background: "rgba(0, 0, 0, 0.5)",
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//   };

//   const modalContentStyle = {
//     background: "#fff",
//     padding: "20px",
//     borderRadius: "8px",
//     maxWidth: "80%",
//     maxHeight: "80%",
//     overflowY: "auto",
//     position: "relative",
//     zIndex: "2",
//   };

//   const modalCloseButtonStyle = {
//     position: "absolute",
//     top: "10px",
//     right: "10px",
//     fontSize: "20px",
//     cursor: "pointer",
//     border: "none",
//     background: "transparent",
//     color: "#000",
//   };

//   // Add an event listener to handle clicks outside of the modal content
//   // useEffect(() => {
//   //   const handleOutsideClick = (event) => {
//   //     if (event.target.classList.contains("modal-overlay")) {
//   //       onClose();
//   //     }
//   //   };

//   //   document.addEventListener("click", handleOutsideClick);

//   //   return () => {
//   //     document.removeEventListener("click", handleOutsideClick);
//   //   };
//   // }, []);

//   return (
//     <div
//       style={modalOverlayStyle}
//       // onClick={onClose}
//       isOpen={isOpen}
//       // onAfterOpen={afterOpenModal}
//       onRequestClose={onClose}
//     >
//       <div style={modalContentStyle}>
//         <button style={modalCloseButtonStyle} onClick={onClose}>
//           &times;
//         </button>
//         {children}
//       </div>
//     </div>
//   );
// };

// export default Modal;

import React from "react";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

// function App() {
// const Modal = ({ isOpen, onClose, children }) => {
const BootstrapModal = ({ modalIsOpen, setIsOpen, children }) => {
  // let subtitle;

  function openModal() {
    setIsOpen(true);
  }

  // function afterOpenModal() {
  //   // references are now sync'd and can be accessed.
  //   subtitle.style.color = "#f00";
  // }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div>
      {/* <button onClick={openModal}>Open Modal</button> */}
      <Modal
        isOpen={modalIsOpen}
        // onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> */}
        {/* <button onClick={closeModal}>close</button>
        <div>I am a modal</div>
        <form>
          <button>tab navigation</button>
          <button>stays</button>
          <button>inside</button>
          <button>the modal</button>
        </form> */}
        {children}
      </Modal>
    </div>
  );
};

export default BootstrapModal;
