import * as React from "react";
import { PieChart } from "@mui/x-charts/PieChart";

export default function BasicPie({ data }) {
  const chartOptions = {
    maintainAspectRatio: false, // To control the aspect ratio
    viewBox: "0 0 600 400",
  };
  return (
    <PieChart
      series={[
        {
          data: data,
          arcLabelMinAngle: 45,
        },
      ]}
      // sx={{
      //   [`& .${pieArcLabelClasses.root}`]: {
      //     fill: "white",
      //     fontWeight: "bold",
      //   },
      // }}
      width={400}
      height={200}
      options={chartOptions}
    />
  );
}
