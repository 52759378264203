import PropTypes from "prop-types";
// import { Button as MuiButton } from "@mui/material";
import styled from "styled-components";
import LoadingButton from "@mui/lab/LoadingButton";

const ButtonWrapper = styled.button`
  // background-color: green;
  padding: 10px 20px;
  // background-color: red;
  // background-image: linear-gradient(261.78deg, #BEEE62 -1.89%, #68CEC7 99.57%)
  // color: #fff;
  background: linear-gradient(261.78deg, #beee62 -1.89%, #68cec7 99.57%);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
`;

function Button({ variant, children, loading = false, ...props }) {
  return (
    <ButtonWrapper {...props}>
      {loading ? (
        <>
          <LoadingButton loading variant="outlined"></LoadingButton>
        </>
      ) : (
        <> {children}</>
      )}
    </ButtonWrapper>
    // <MuiButton variant={variant} {...props}>
    //   {children}
    // </MuiButton>
  );
}

// Button.propTypes = {
//   variant: PropTypes.string,
//   disableFocusRipple: PropTypes.bool,
//   children: PropTypes.node,
// };

// Button.defaultProps = {
//   variant: "contained",
//   children: "Text Goes Here",
//   disableFocusRipple: true,
// };

export default Button;
