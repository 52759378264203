import { red } from "@mui/material/colors";
import React, { useState, useEffect } from "react";

const WidgetPreview = () => {
  const [data, setData] = useState();

  const getUserWidget = async () => {
    await fetch(`https://dev4.instantsolutionslab.site/api/get-my-widgets`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiYmFjZjg0NGUxYzhiM2M3Yjc0ODhmOTUzMDUyMTRiOTNiZmQ1NjE3NmNhMGRhZWE5NDc0NGQ3MzI3NzIwNjFmZDdhNjRkMmMxOTZiY2MyODUiLCJpYXQiOjE2OTI4OTc3NzMuMzUwOTk1LCJuYmYiOjE2OTI4OTc3NzMuMzUxMDA0LCJleHAiOjE3MjQ1MjAxNzMuMzQ2NDg2LCJzdWIiOiIzMCIsInNjb3BlcyI6W119.ZlP92p5RimC9bxolUIUOF9CZ44P1YHtIECcrJU6HfOxjZbw3Rx4zmxmGE0sY62rF3WWMDWLOu2Y7o_-ATvrINZiAet4BzEgP0YEGXVV3rhR6mO6GhCLgUQbEqhNMZV_Xl6LjSAj9R3yMizFRO64T4MBCETAG4juy-81RFUuwnlh9KbyKBvyfqPAtj8degVGnO3q6G0DnJn5LFOp4NF1b4I4gaXQbKN-NBm0xBz3LFzsBW4j1vCE2zSgLwKI9grpQ5pUby0gOR8Pm0Sje9jHdqwSJ4UgotFfdMfje3RV2u8Cu9EKZNcQ4mqQUSCoNU6oXYWSGZJ6NtgrUCDNT9IlEdTx1DZb--G30bGB_8CZiDwwQ-17_JoyWD86GiNBwnlcbCx5IKm4mOE89i23-go_YUY6OHXHUAZgquZEOWhNovDw1soEiI2ull9NLVuaV2nRfMQP6l0ClI73Q-IrROBSkek46366wGmEFKsVYOxIESjzYjmWKJmWKuIyrmUR2D3LJNXtZ5-OZx3v0vTEJrwt8rFzAzwryIGIW8CBGu_PIrvpH8uHvwJX45dxUPCt3E9RGEqkGaPJSS776N0G8MTT77GDAnQLy9Khe3ejXF6O4m0ipspfgqw5cpfoqXFIcjd1j_H1gOzaG-vKGuQTxHRNyTpX-KIvd8ZJTmV-ULqplG4c`,
      },
    })
      .then((resp) => resp.json())
      .then((json) => {
        setData(json?.data);
        // setRetirementTypeOption(json?.data?.data);
        console.log(json?.data, "json?.data");
        // setGetMyWidgetData(json?.data);
        // setBlockchainCurrency(json?.data);
      });
  };

  useEffect(() => {
    getUserWidget();
  }, []);

  console.log(data?.background_color);
  return (
    <div>
      {data && (
        <>
          <iframe
            src="https://pay4climate.instantsolutionslab.site/widgetview"
            // src="http://localhost:3000/widgetview"
            style={{
              width: "430px",
              // height: "257px",
              height: "226px",
              border: "none",
              borderRadius: "10px",
              position: "absolute",
              bottom: "2%",
              right: "2%",
              boxShadow: "4px 10px 12px 5px #000000b8",
              // backgroundColor: data?.background_color,
              // backgroundColor: "#333d3e",
              backgroundColor: "rgb(51, 61, 62)",
              // color: data?.text_color,
              color: "#acf9e9",
              fontFamily: data?.font,

              // backgroundColor: "red",
            }}
            title="Iframe Example"
          ></iframe>
        </>
      )}
    </div>
  );
};

export default WidgetPreview;
